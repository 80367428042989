import api from './../utils/api';

const proxy = ({ data }) => data;

let cache = {};

const search = (page, pageSize, filters = [], sorting = {}) => {
  const search = { filters };

  if (sorting && sorting.field) {
    search.dir = sorting.dir;
    search.sortBy = sorting.field;
  }

  const query = `search=${JSON.stringify(search)}&offset=${page * pageSize}&size=${pageSize}`;

  if (typeof cache[query] === 'undefined') {
    cache[query] = api.get(`/reset-password-logs?${query}`).then(proxy);
  }

  return cache[query];
};

export default {
  search,
}
