import { push } from 'react-router-redux';

import { error } from './notification';
import payments from '../services/payments';
import mocks from '../utils/mocks';

// Actions
const SET_PAGE = 'admin/payments/SET_PAGE';
const LOADING_LIST = 'admin/payments/LOADING_LIST';

const SET_PAYMENTS_HISTORY = 'admin/payments/SET_PAYMENTS_HISTORY';
const SET_PAYMENTS_BATCH = 'admin/payments/SET_PAYMENTS_BATCH';
const SET_PAYMENTS_ORDERS = 'admin/payments/SET_PAYMENTS_ORDERS';
const SET_PAYMENTS_CUSTOMERS = 'admin/payments/SET_PAYMENTS_CUSTOMERS';

// initial state
const INITIAL_STATE = {
  payments: {
    history: mocks.history,
    batch: [],
    orders: [],
    customers: [],
  },
  loading: false,
}

// Reducer
export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case LOADING_LIST:
      return {
        ...state,
        loading: action.payload,
      };

    case SET_PAYMENTS_HISTORY:
      return {
        ...state,
        payments: {
          ...state.payments,
          history: action.payload
        },
      };

    case SET_PAGE:
      const page = {
        ...state,
        page: {
          total: action.payload.total,
          currentPage: action.payload.offset / action.payload.size,
          pageSize: action.payload.size
        },
      };

      return page;

    default:
      return state;
  }
}

// Action Creators
export function getPaymentsHistory() {
  return function (dispatch) {
    dispatch({ type: LOADING_LIST, payload: true });

    payments.history()
      .then(({ history }) => {
        dispatch({ type: SET_PAYMENTS_HISTORY, payload: history });

        dispatch({ type: LOADING_LIST, payload: false });
      })
      .catch(({ response }) => {
        dispatch(error(response));

        dispatch(push('/payments/history'));
      });
  }
}

export function getPaymentsBatch() {
  return function (dispatch) {
    dispatch({ type: LOADING_LIST, payload: true });

    payments.batch()
      .then(({ batch }) => {
        dispatch({ type: SET_PAYMENTS_BATCH, payload: batch });

        dispatch({ type: LOADING_LIST, payload: false });
      })
      .catch(({ response }) => {
        dispatch(error(response));

        dispatch(push('/payments/batch'));
      });
  }
}

export function getPaymentsOrders() {
  return function (dispatch) {
    dispatch({ type: LOADING_LIST, payload: true });

    payments.orders()
      .then(({ orders }) => {
        dispatch({ type: SET_PAYMENTS_ORDERS, payload: orders });

        dispatch({ type: LOADING_LIST, payload: false });
      })
      .catch(({ response }) => {
        dispatch(error(response));

        dispatch(push('/payments/orders'));
      });
  }
}

export function getPaymentsCustomers() {
  return function (dispatch) {
    dispatch({ type: LOADING_LIST, payload: true });

    payments.customers()
      .then(({ customers }) => {
        dispatch({ type: SET_PAYMENTS_CUSTOMERS, payload: customers });

        dispatch({ type: LOADING_LIST, payload: false });
      })
      .catch(({ response }) => {
        dispatch(error(response));

        dispatch(push('/payments/customers'));
      });
  }
}

export function searchPayments(page = 0, pageSize = 100, filters = [], sorting = []) {

  return function (dispatch) {
    dispatch({ type: LOADING_LIST, payload: true });

    payments.search(page, pageSize, filters, sorting)
      .then(({ data, meta }) => {
        dispatch({ type: SET_PAYMENTS_HISTORY, payload: data });
        dispatch({ type: SET_PAGE, payload: meta });
        dispatch({ type: LOADING_LIST, payload: false });
      })
      .catch(({ response }) => {
        dispatch(error(response));

        dispatch({ type: LOADING_LIST, payload: false });
      });
  };
}
