import api from '../utils/api';

// support send service
const send = ({ type, ...payload }) => api.post(`member/support/${type}`, payload)
  .then(resp => resp.data);


export default {
  send,
};
