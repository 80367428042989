import history from './history';
import batch from './batch';
import orders from './orders';

const payments = {
  history,
  batch,
  orders,
};

export default payments;