import api from './../utils/api';

const proxy = ({ data }) => data;
const me = () => api && api.get('/me').then(proxy);
const users = () => api && api.get('/users').then(proxy);
const session = (token) => api && api.post('/auth/social/google', { token }).then(proxy);
const refresh = (token) => api && api.post('/auth/refresh', { token }).then(proxy);

export default {
  me,
  users,
  session,
  refresh,
};
