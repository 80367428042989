import supportServices from '../services/support';

// Actions
const SET_ERRORS = 'MembersApp/support/SET_ERRORS';
const SET_LOADING = 'MembersApp/support/SET_LOADING';
const SET_STATUS = 'MembersApp/support/SET_STATUS';

// Initial state
const INITIAL_STATE = {
  status: false,
  loading: false,
  errors: null,
};

// Reducer
export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case SET_STATUS:
    return {
        ...state,
        status: action.payload,
    }
    case SET_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
}

// Action Creators
export function setErrors(payload) {
  return dispatch => {
    dispatch({
      type: SET_ERRORS,
      payload,
    });
  };
}

export function setLoading(payload) {
  return dispatch => {
    dispatch({
      type: SET_LOADING,
      payload,
    });
  };
}

export function setSupport(payload) {
  return dispatch => {
    dispatch(setLoading(true));
    return supportServices.send(payload)
      .then(response => {
        dispatch(setErrors(null)); // reset errors
        dispatch({ type: SET_STATUS, payload: true });
        dispatch(setLoading(false));
      })
      .catch(err => {
        const errCode = err.response ? err.response.status : 500;
        const errMessage = err.response ? err.response.data : err;

        dispatch(setLoading(false));
        dispatch({ type: SET_STATUS, payload: false });

        dispatch(
          setErrors({
            code: errCode,
            message: errMessage,
          }),
        );
      });
  };
}
