import { error } from './notification';
import campaigns from '../services/campaigns';

// Actions
const GET_HISTORY = 'admin/campaigns/GET_HISTORY';
const GET_COUNTRIES = 'admin/campaigns/GET_COUNTRIES';
const GET_STATES = 'admin/campaigns/GET_STATES';
const SET_MEMBERS_LIST = 'admin/campaigns/SET_MEMBERS_LIST';
const SET_MEMBERS_IDS = 'admin/campaigns/SET_MEMBERS_IDS';
const SET_MEMBERS_PAGE = 'admin/campaigns/SET_MEMBERS_PAGE';
const LOADING_MEMBERS = 'admin/campaigns/LOADING_MEMBERS';
const LOADING_MEMBERS_IDS = 'admin/campaigns/LOADING_MEMBERS_IDS';
const SET_CAMPAIGN_COUNTRIES = 'admin/campaigns/SET_CAMPAIGN_COUNTRIES';
const SET_CAMPAIGN_STATES = 'admin/campaigns/SET_CAMPAIGN_STATES';
const SET_CAMPAIGN_MEMBERS = 'admin/campaigns/SET_CAMPAIGN_MEMBERS';
const SET_CAMPAIGN_MESSAGE = 'admin/campaigns/SET_CAMPAIGN_MESSAGE';
const SET_CAMPAIGN_MESSAGE_SCHEDULE = 'admin/campaigns/SET_CAMPAIGN_MESSAGE_SCHEDULE';
const SET_CAMPAIGN_CREATED = 'admin/campaigns/SET_CAMPAIGN_CREATED';
const SET_PAGE = 'admin/campaigns/SET_PAGE';
const SET_LOADING = 'admin/campaigns/SET_LOADING';

const INITIAL_STATE = {
  campaign: {
    countries: [],
    states: [],
    members: [],
    message: ``,
    scheduleAt: null,
    created: false,
  },
  loading: false,
  loadingMembers: false,
  loadingMembersIds: false,
  countries: [],
  list: [],
  states: [],
  members: [],
  allMembersIds:[],
  page: {
    total: 0,
    currentPage: 0,
    pageSize: 100
  },
  history:[]
};

// Reducer
export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case LOADING_MEMBERS:
      return {
        ...state,
        loadingMembers: action.payload,
      };
    case LOADING_MEMBERS_IDS:
      return {
        ...state,
        loadingMembersIds: action.payload,
      };
    case SET_PAGE:
    case SET_MEMBERS_PAGE:
      return {
        ...state,
        page: {
          total: action.payload.total,
          currentPage: action.payload.offset /  action.payload.size,
          pageSize: action.payload.size
        },
      };
    case GET_HISTORY:
      return {
        ...state,
        history: action.payload,
      };
    case GET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };
      case GET_STATES:
        return {
          ...state,
          states: action.payload,
        };
      case SET_MEMBERS_LIST:
        return {
          ...state,
          members: action.payload,
        };
      case SET_MEMBERS_IDS:
        return {
          ...state,
          allMembersIds: action.payload,
        };
    case SET_CAMPAIGN_COUNTRIES:
      const campaignCountries = state.campaign;
      campaignCountries.countries = action.payload;
  
      return {
        ...state,
        campaign: campaignCountries,
      };
    case SET_CAMPAIGN_STATES:
      const campaignStates = state.campaign;
      campaignStates.states = action.payload;
  
      return {
        ...state,
        campaign: campaignStates,
      };
    case SET_CAMPAIGN_MEMBERS:
      const campaignMembers = state.campaign;
      campaignMembers.members = action.payload;
  
      return {
        ...state,
        campaign: campaignMembers,
      };
    case SET_CAMPAIGN_MESSAGE:
      const campaignMessage = state.campaign;
      campaignMessage.message = action.payload;
  
      return {
        ...state,
        campaign: campaignMessage,
      };
    case SET_CAMPAIGN_MESSAGE_SCHEDULE:
      const campaignMessageSchedule = state.campaign;
      campaignMessageSchedule.scheduleAt = action.payload;
  
      return {
        ...state,
        campaign: campaignMessageSchedule,
      };
    case SET_CAMPAIGN_CREATED:
      const campaignCreated = state.campaign;
      campaignCreated.created = action.payload;
  
      return {
        ...state,
        campaign: campaignCreated,
      };
    default:
      return state;
  }
}

// Action Creators
export function setCountries(payload) {
  return function (dispatch) {
    dispatch({ type: SET_CAMPAIGN_COUNTRIES, payload });
    dispatch({ type: SET_CAMPAIGN_STATES, payload: [] });
  }
}

export function setStates(payload) {
  return function (dispatch) {
    dispatch({ type: SET_CAMPAIGN_STATES, payload });
    dispatch({ type: SET_CAMPAIGN_MEMBERS, payload: [] });
  }
}

export function setCampaignMembers(payload) {
  return function (dispatch) {
    dispatch({ type: SET_CAMPAIGN_MEMBERS, payload });
  }
}

export function setMessage(payload) {
  return function (dispatch) {
    dispatch({type: SET_CAMPAIGN_MESSAGE, payload});
  }
}

export function setMessageSchedule(payload) {
  return function (dispatch) {
    dispatch({type: SET_CAMPAIGN_MESSAGE_SCHEDULE, payload});
  }
}

export function getHistory() {
  return function (dispatch) {
    dispatch({ type: SET_LOADING, payload: true });

    campaigns.history()
      .then(({ data }) => {
        dispatch({ type: GET_HISTORY, payload: data });
        dispatch({ type: SET_LOADING, payload: false });
      })
      .catch(err => {
        dispatch(error(err));
        dispatch({ type: SET_LOADING, payload: false });
      })
  }
}

export function getCountries() {
  return function (dispatch) {
    dispatch({ type: SET_LOADING, payload: true });

    campaigns.countries()
      .then(({ data }) => {
        dispatch({ type: GET_COUNTRIES, payload: data });
        dispatch({ type: SET_LOADING, payload: false });
      })
      .catch(err => {
        dispatch(error(err));
        dispatch({ type: SET_LOADING, payload: false });
      })
  }
}

export function getStates() {
  return function (dispatch, getState) {
    dispatch({ type: SET_LOADING, payload: true });

    const campaignsState = getState().campaigns;
    const campaignsCountries = campaignsState.campaign.countries;
    const filters = campaignsCountries.length ? [{
      "field": "countryId",
      "value": campaignsCountries 
    }] : null;

    campaigns.states(filters)
      .then(({ data, meta }) => {
        dispatch({ type: GET_STATES, payload: data });
        dispatch({ type: SET_LOADING, payload: false });
      })
      .catch(({ response }) => {
        dispatch(error(response));

        dispatch({ type: SET_LOADING, payload: false });
      });
  };
}

export function getAllMembersIds() {
  return function (dispatch, getState) {
    dispatch({ type: LOADING_MEMBERS_IDS, payload: true });

    const campaignsState = getState().campaigns;
    const campaignsCountries = campaignsState.campaign.countries;
    const campaignsStates = campaignsState.campaign.states;
    let filters = null;

    if (campaignsStates.length) {
      filters = [{
        "field": "stateId",
        "value": campaignsStates
      }];
    } else if (campaignsCountries.length) {
      filters = [{
        "field": "countryId",
        "value": campaignsCountries
      }];
    }

    campaigns.allMembersIds(filters)
      .then(({ data, meta }) => {
        dispatch({ type: SET_MEMBERS_IDS, payload: data });
        dispatch({ type: LOADING_MEMBERS_IDS, payload: false });
      })
      .catch(({ response }) => {
        dispatch(error(response));

        dispatch({ type: LOADING_MEMBERS_IDS, payload: false });
      });
  };
}

export function loadMembers(page = 0, pageSize = 100, filters = [], sorting = []) {
  return function(dispatch, getState) {
    dispatch({ type: LOADING_MEMBERS, payload: true });

    const campaignsState = getState().campaigns;
    const campaignsCountries = campaignsState.campaign.countries;
    const campaignsStates = campaignsState.campaign.states;

    let filters = null;

    if (campaignsStates.length) {
      filters = [{
        "field": "stateId",
        "value": campaignsStates
      }];
    } else if (campaignsCountries.length) {
      filters = [{
        "field": "countryId",
        "value": campaignsCountries
      }];
    }

    campaigns.search(page, pageSize, filters, sorting)
      .then(({ data, meta}) => {   
        dispatch({ type: SET_MEMBERS_LIST, payload: data });
        dispatch({ type: SET_MEMBERS_PAGE, payload: meta });
        dispatch({ type: LOADING_MEMBERS, payload: false });
      })
      .catch(({ response }) => {
        dispatch(error(response));

        dispatch({ type: LOADING_MEMBERS, payload: false });
      });
  };
}

export function sendCampaign(payload) {
  return function (dispatch) {
    dispatch({ type: SET_LOADING, payload: true });

    campaigns.send(payload)
      .then(({ data }) => {       
        dispatch({ type: SET_CAMPAIGN_CREATED, payload: true });
        dispatch({ type: SET_LOADING, payload: false });
      })
      .catch(err => {
        dispatch(error(err));
        dispatch({ type: SET_LOADING, payload: false });
      });
  };
}
