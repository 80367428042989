import React from 'react';
import ReactDOM from 'react-dom';

// redux support
import { Provider } from 'react-redux'
// router support
import { ConnectedRouter } from 'react-router-redux'

// module imports
import 'semantic-ui-css/semantic.min.css';
import App from './App';
import './index.css';
// import registerServiceWorker from './registerServiceWorker';
import { history, default as store } from './modules';

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App history={history} />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('splend')
);

// registerServiceWorker();
