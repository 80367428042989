const history = [
  {
    transactionId: 'r7b1q1hv',
    batchId: 4391864272,
    orderId: '5ba9asda09sad',
    fullName: 'John Smith',
    memberId: 10001,
    paymentType: 'debtor',
    amount: 699,
    status: 'failed',
    date: '2018-09-25 17:12:43',
  },

  {
    transactionId: 'a9s8d9sf',
    batchId: 4391864272,
    orderId: '09s0a9sa09ss0',
    fullName: 'Gigi Hadid',
    memberId: 10002,
    paymentType: 'debtor',
    amount: 707,
    status: 'completed',
    date: '2018-09-25 17:13:10',
  },
  {
    transactionId: 'c98a9s8a',
    batchId: 4391864272,
    orderId: '8s7210sda87s1',
    fullName: 'Mike Thomas',
    memberId: 10003,
    paymentType: 'debtor',
    amount: 1079,
    status: 'failed',
    date: '2018-09-25 17:32:23',
  },
]

export default history;