import api from './../utils/api';

const proxy = ({ data }) => data;

const msr = () => api.get(`/reports/msr`).then(proxy);

const missing = payload => api.post(`reports/missing-active-members`, payload).then(proxy);

const leads = (payload) => api.get(`/reports/leads?${payload}`).then(proxy);

const uberPermissionsCSV = (from, to, uberStatus) => {
    const query = `start=${from}&end=${to}&status=${uberStatus}`;

    return api.get(`/reports/curs/export?${query}`).then(proxy);
};

const leadsSignupCSV = (from, to, country, filters = {}, { field: sortBy = 'Internal_id', dir = 'asc' }) => {
    const query = `start=${from}&end=${to}&country=${country}&search=${JSON.stringify({ filters, sortBy, dir })}`;

    return api.get(`/reports/leads_signup/export?${query}`).then(proxy);
};

const weeklyTotalEarningsCSV = (from, filters = {}, { field: sortBy = 'Member ID', dir = 'asc' }) => {
    const query = `start=${from}&search=${JSON.stringify({ filters, sortBy, dir })}`;

    return api.get(`/reports/total-weekly-earnings/export?${query}`).then(proxy);
};

const weeklyTotalPaymentsCSV = (from, to, paymentCategory, filters = {}, { field: sortBy = 'Member ID', dir = 'asc' }) => {
    const query = `start=${from}&end=${to}&category=${paymentCategory}&search=${JSON.stringify({ filters, sortBy, dir })}`;

    return api.get(`/reports/total-weekly-payments/export?${query}`).then(proxy);
};

const weeklyAverageEarningsCSV = (from, filters = {}, { field: sortBy = 'Member ID', dir = 'asc' }) => {
    const query = `start=${from}&search=${JSON.stringify({ filters, sortBy, dir })}`;

    return api.get(`/reports/average-weekly-earnings/export?${query}`).then(proxy);
};

const weeklyKmAndEarningsCSV = (from, filters = {}, { field: sortBy = 'CRMID', dir = 'asc' }) => {
    const query = `start=${from}&search=${JSON.stringify({ filters, sortBy, dir })}`;

    return api.get(`/reports/weekly-km-earnings/export?${query}`).then(proxy);
};

const dailyKmAndEarningsCSV = (from, filters = {}, { field: sortBy = 'CRMID', dir = 'asc' }) => {
    const query = `start=${from}&search=${JSON.stringify({ filters, sortBy, dir })}`;

    return api.get(`/reports/daily-km-earnings/export?${query}`).then(proxy);
};

const paygCSV = (from, filters = {}, { field: sortBy = '', dir = 'asc' }) => {
  const query = `start=${from}&search=${JSON.stringify({ filters, sortBy, dir })}`;

  return api.get(`/reports/payg/export?${query}`).then(proxy);
};

const hourlyEarningsCSV = (from, to, filters = {}, { field: sortBy = 'ZohoCRMID', dir = 'asc' }) => {
  const query = `start=${from}&end=${to}&search=${JSON.stringify({ filters, sortBy, dir })}`;

  return api.get(`/reports/hourly-earnings/export?${query}`).then(proxy);
};

const hourlyTripsCSV = (from, to, filters = {}, { field: sortBy = 'ZohoCRMID', dir = 'asc' }) => {
  const query = `start=${from}&end=${to}&search=${JSON.stringify({ filters, sortBy, dir })}`;

  return api.get(`/reports/hourly-trips/export?${query}`).then(proxy);
};

const searchUberPermissions = (from, to) => {
    const query = `start=${from}&end=${to}`;

    return api.get(`/reports/curs?${query}`).then(proxy);
};

const searchLeadsSignup = (from, to, country, page, pageSize, filters = {}, { field: sortBy = 'Internal_id', dir = 'asc' }) => {
    const query = `start=${from}&end=${to}&country=${country}&search=${JSON.stringify({ filters, sortBy, dir })}&offset=${page * pageSize}&size=${pageSize}`;

    return api.get(`/reports/leads_signup?${query}`).then(proxy);
};

const searchWeeklyTotalEarnings = (from, to, page, pageSize, filters = {}, { field: sortBy = 'Member ID', dir = 'asc' }) => {
    const query = `start=${from}&end=${to}&search=${JSON.stringify({ filters, sortBy, dir })}&offset=${page * pageSize}&size=${pageSize}`;

    return api.get(`/reports/total-weekly-earnings?${query}`).then(proxy);
};

const searchWeeklyTotalPayments = (from, to, paymentCategory, page, pageSize, filters = {}, { field: sortBy = 'Member ID', dir = 'asc' }) => {
    const query = `start=${from}&end=${to}&category=${paymentCategory}&search=${JSON.stringify({ filters, sortBy, dir })}&offset=${page * pageSize}&size=${pageSize}`;

    return api.get(`/reports/total-weekly-payments?${query}`).then(proxy);
};

const searchWeeklyAverageEarnings = (from, to, page, pageSize, filters = {}, { field: sortBy = 'Member ID', dir = 'asc' }) => {
    const query = `start=${from}&end=${to}&search=${JSON.stringify({ filters, sortBy, dir })}&offset=${page * pageSize}&size=${pageSize}`;

    return api.get(`/reports/average-weekly-earnings?${query}`).then(proxy);
};

const searchWeeklyKmAndEarnings = (from, to, page, pageSize, filters = {}, { field: sortBy = 'CRMID', dir = 'asc' }) => {
    const query = `start=${from}&end=${to}&search=${JSON.stringify({ filters, sortBy, dir })}&offset=${page * pageSize}&size=${pageSize}`;

    return api.get(`/reports/weekly-km-earnings?${query}`).then(proxy);
};

const searchDailyKmAndEarnings = (from, to, page, pageSize, filters = {}, { field: sortBy = 'CRMID', dir = 'asc' }) => {
    const query = `start=${from}&end=${to}&search=${JSON.stringify({ filters, sortBy, dir })}&offset=${page * pageSize}&size=${pageSize}`;

    return api.get(`/reports/daily-km-earnings?${query}`).then(proxy);
};

const searchPayg = (from, page, pageSize, filters = {}, { field: sortBy = '', dir = 'asc' }) => {
  const query = `start=${from}&search=${JSON.stringify({ filters, sortBy, dir })}&offset=${page * pageSize}&size=${pageSize}`;

  return api.get(`/reports/payg?${query}`).then(proxy);
};

const searchHourlyEarnings = (from, to, page, pageSize, filters = {}, { field: sortBy = 'ZohoCRMID', dir = 'asc' }) => {
  const query = `start=${from}&end=${to}&search=${JSON.stringify({ filters, sortBy, dir })}&offset=${page * pageSize}&size=${pageSize}`;

  return api.get(`/reports/hourly-earnings?${query}`).then(proxy);
};

const searchHourlyTrips = (from, to, page, pageSize, filters = {}, { field: sortBy = 'ZohoCRMID', dir = 'asc' }) => {
  const query = `start=${from}&end=${to}&search=${JSON.stringify({ filters, sortBy, dir })}&offset=${page * pageSize}&size=${pageSize}`;

  return api.get(`/reports/hourly-trips?${query}`).then(proxy);
};

const exportMemberCSV = (id, payload) => api.get(`/reports/members/${id}/stats?${payload}`).then(proxy);

const exportMembersCSV = ({page, pageSize, filters = [], sorting = {}}) => {
  const exportMembersFilters = { filters };

  if (sorting && sorting.field) {
    exportMembersFilters.dir = sorting.dir;
    exportMembersFilters.sortBy = sorting.field;
  }

  const query = `search=${JSON.stringify(exportMembersFilters)}&offset=${page * pageSize}&size=${pageSize}`;

  return api.get(`/reports/members?${query}`).then(proxy);
};

const downloadCSV = (url) => fetch(url).then(function(response) {
  if (!response.ok) {
    throw new Error("HTTP error, status = " + response.status);
  }
  return response.blob();
});

export default {
    msr,
    missing,
    leads,
    uberPermissionsCSV,
    weeklyTotalEarningsCSV,
    weeklyTotalPaymentsCSV,
    weeklyAverageEarningsCSV,
    weeklyKmAndEarningsCSV,
    dailyKmAndEarningsCSV,
    paygCSV,
    hourlyTripsCSV,
    hourlyEarningsCSV,
    exportMemberCSV,
    exportMembersCSV,
    downloadCSV,
    searchLeadsSignup,
    searchUberPermissions,
    searchWeeklyTotalEarnings,
    searchWeeklyTotalPayments,
    searchWeeklyAverageEarnings,
    searchWeeklyKmAndEarnings,
    searchDailyKmAndEarnings,
    searchPayg,
    searchHourlyTrips,
    searchHourlyEarnings,
    leadsSignupCSV,
}
