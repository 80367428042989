export default [
    {
        Header: 'Internal id',
        accessor: "Internal_id",
        filterable: true,
        query: {
            field: "Internal_id",
            matchingValue: 'like',
        },
        sortable: true,
        width: 120,
    },
    {
        Header: 'Member',
        accessor: "Name",
        query: {
            field: "Name",
            matchingValue: 'like',
        },
        sortable: false,
        filterable: true,
        width: 200,
    },
    {
        Header: 'Status',
        accessor: "Status",
        width: 100,
        sortable: false,
        resizable: false,
        filterConfig: {
            type: 'select',
            values: [
                { value: '', label: 'any' },
                { value: 'active', label: 'active'},
                { value: 'lead', label: 'lead' },
                { value: 'deleted', label: 'deleted' },
                { value: 'inactive', label: 'inactive' },
            ]
        },
    },
    {
        Header: 'Msr email',
        accessor: "Msr_email",
        filterable: true,
        query: {
            field: "Msr_email",
            matchingValue: 'like',
        },
        sortable: false,
        width: 200,
    },
];
