const batch = [
  {
    batchId: 6771840347,
    batchType: 'debtor',
    processed: 33,
    successful: 1,
    failed: 32,
    total: 38.63,
    started: '2018-10-01 10:10:00',
    ended: '2018-10-01 10:30:00',
    transactions: [
      {
        id: '0a09asd0',
        crmId: '1685340000000038585030',
        customer: `Jessica Clark`,
        paymentType: `subscription`,
        amount: 199.00,
        date: '2018-09-30 17:38:09',
        status: `successful`,
      },
      {
        id: 'as981298',
        crmId: '1685340000000038121120',
        customer: `Matt Clark`,
        paymentType: `subscription`,
        amount: 269.00,
        date: '2018-09-30 17:08:39',
        status: `successful`,
      },
      {
        id: 'f87as98s',
        crmId: '1685340000000023983310',
        customer: `John Clark`,
        paymentType: `subscription`,
        amount: 269.00,
        date: '2018-09-30 16:48:19',
        status: `successful`,
      },
    ]
  },
  {
    batchId: 4391864272,
    batchType: 'adhoc',
    processed: 706,
    successful: 607,
    failed: 99,
    total: 28288.02,
    started: '2018-09-30 09:00:01',
    ended: '2018-09-30 09:30:00',
    transactions: [
      {
        id: '0a09asd0',
        crmId: '1685340000000038585030',
        customer: `Sun Ho`,
        paymentType: `subscription`,
        amount: 269.00,
        date: '2018-09-30 17:38:09',
        status: `successful`,
      },
    ]
  },
  {
    batchId: 8451882033,
    batchType: 'rental',
    processed: 1205,
    successful: 1025,
    failed: 180,
    total: 278460.00,
    started: '2018-09-28 10:02:37',
    ended: '2018-09-28 10:32:34',
  },
  {
    batchId: 5122091092,
    batchType: 'debtor',
    processed: 36,
    successful: 4,
    failed: 32,
    total: 574.01,
    started: '2018-09-25 09:21:37',
    ended: '2018-09-25 09:27:34',
  },
  {
    batchId: 75092440411,
    batchType: 'debtor',
    processed: 27,
    successful: 2,
    failed: 25,
    total: 293.15,
    started: '2018-09-20 21:01:37',
    ended: '2018-09-20 21:31:34',
  },
]

export default batch;