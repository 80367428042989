import { clear } from './../modules/notification';
import Notification from './../components/Notification/Notification';

const norifier = store => next => action => {
  const result = next(action);
  const { notification } = store.getState();

  if (notification) {
    const { type, message } = notification;

    Notification[type](message);

    store.dispatch(clear());
  }

  return result;
}

export default norifier;
