import api from './../utils/api';

const proxy = ({ data }) => data;

const history = () => api.get(`/payments/history`).then(proxy);
const batch = () => api.get(`/payments/batch`).then(proxy);
const orders = () => api.get(`/payments/orders`).then(proxy);
const customers = () => api.get(`/payments/customers`).then(proxy);

let cache = {};

const search = (page, pageSize, filters = [], sorting = {}) => {
  const search = { filters };

  if (sorting && sorting.field) {
    search.dir = sorting.dir;
    search.sortBy = sorting.field;
  }

  const query = `search=${JSON.stringify(search)}&offset=${page * pageSize}&size=${pageSize}`;

  if (typeof cache[query] === 'undefined') {
    cache[query] = api.get(`/payments?${query}`).then(proxy);
  }

  return cache[query];
};

export default {
  history,
  batch,
  orders,
  customers,
  search,
}
