import React from 'react';
import { NavLink } from 'react-router-dom'
import { Image, Button } from 'semantic-ui-react'

import './header.css'
import app from '../../../package.json';

class Header extends React.Component {
  renderAvatar = () => {
    const { user } = this.props;

    return (
      <div className='user'>
        <Image avatar src={user.avatar} />
        &nbsp;
        <span className='username'>{user.name}</span>
        &nbsp;
        <Button
          basic
          circular
          size='mini'
          icon='sign out'
          onClick={() => this.props.onLogout()} />
      </div>
    );
  }

  render() {
    const { user } = this.props;
    if (!user) return null;

    return (
      <header className="main-header">
        <div className="logo">
          <NavLink to="/">
            <div style={{ "display": "flex" }}>
              <Image height={50} src="/images/logo-small-daytime.svg" />
              <span style={{marginLeft: 10}}>v{app.version}</span>
            </div>
          </NavLink>
        </div>

        {this.renderAvatar()}
      </header>
    );
  }
}

Header.displayName = 'Components/Header'

Header.defaultProps = {
  selectedCountry: 'global',
  user: null,
  onLogout: () => {}
}

export default Header;
