import React, { Component } from 'react';
import { Switch, Route } from 'react-router';
import Loadable from 'react-loadable';

import Loading from './components/Loading/Loading';
import Layout from './containers/Layout/Layout';

const LoadableProps = {
  loading: Loading,
  delay: 1000
};

const Dashboard = Loadable({
  loader: () => import('./containers/Dashboard/Dashboard'),
  ...LoadableProps
});

const Login = Loadable({
  loader: () => import('./containers/Login/Login'),
  ...LoadableProps
});

const CreateTestMembers = Loadable({
  loader: () => import('./containers/Members/CreateTestMembers'),
  ...LoadableProps
});

const newCampaign = Loadable({
  loader: () => import('./containers/Campaigns/newCampaign'),
  ...LoadableProps
});

const Members = Loadable({
  loader: () => import('./containers/Members/Members'),
  ...LoadableProps
});

const Vehicles = Loadable({
  loader: () => import('./containers/Vehicles/Vehicles'),
  ...LoadableProps
});

const Reports = Loadable({
  loader: () => import('./containers/Reports/Reports'),
  ...LoadableProps
});

const Payments = Loadable({
  loader: () => import('./containers/Payments/Payments'),
  ...LoadableProps
});

const Campaigns = Loadable({
  loader: () => import('./containers/Campaigns/Campaigns'),
  ...LoadableProps
});

const Tickets = Loadable({
  loader: () => import('./containers/Tickets/Tickets'),
  ...LoadableProps
});

const LoginLogs = Loadable({
  loader: () => import('./containers/LoginLogs/LoginLogs'),
  ...LoadableProps
});

const ResetPasswordLogs = Loadable({
  loader: () => import('./containers/ResetPasswordLogs/ResetPasswordLogs'),
  ...LoadableProps
});

const Packages = Loadable({
  loader: () => import('./containers/Packages/Packages'),
  ...LoadableProps
});

const ConsistencyChecks = Loadable({
  loader: () => import('./containers/ConsistencyChecks/ConsistencyChecks'),
  ...LoadableProps
});

class App extends Component {
  render() {
    const { history } = this.props;
    return (
      <Layout history={history}>
        <Switch>
          <Route exact path='/' component={Dashboard} />
          <Route exact path='/audit/log-attempts/' component={LoginLogs} />
          <Route exact path='/audit/reset-password-log-attempts/' component={ResetPasswordLogs} />
          <Route exact path='/packages/' component={Packages} />
          <Route exact path='/members/create-test' component={CreateTestMembers} />
          <Route exact path='/campaigns/create-campaign' component={newCampaign} />
          <Route path='/campaigns/:campaignId?/:action?' component={Campaigns} />
          <Route path='/members/:memberId?/:action?' component={Members} />
          <Route path='/vehicles/:vehicleId?/:action?' component={Vehicles} />
          <Route path='/reports/:report?' component={Reports} />
          <Route path='/consistency-checks/:consistencyCheckId?' component={ConsistencyChecks} />
          <Route path='/zendesk-ticket/' component={Tickets} />
          <Route path='/payments/:payment?/:paymentId?' component={Payments} />
          <Route path='/login' component={Login} />
        </Switch>
      </Layout>
    );
  }
}

export default App;
