const orders = [
  {
    orderId: '5ba9asda09sad',
    date: '2018-09-25 17:12:43',
    fullName: 'John Smith',
    items: [
      {
        name: 'Other Adhoc',
        description: 'Other Adhoc',
        price: 199.00,
        quantity: 1,
        customer: `Jessica Clark`,        
      },
      {
        name: 'Insurance Excess',
        description: 'Insurance invoice ',
        price: 420.5,
        quantity: 1,
        customer: `Jacko Paste`,        
      },
      {
        name: 'Charge for damage',
        description: 'Accident report 23511675 ',
        price: 744.9,
        quantity: 1,
        customer: `Jacko Paste`,        
      },
    ]
  },
  
  {
    orderId: '09s0a9sa09ss0',
    date: '2018-09-25 17:13:10',
    fullName: 'Gigi Hadid',
    items: [
      {
        name: 'Replacement Key',
        description: 'Replacement Key for CD299A',
        price: 210.00,
        quantity: 1,
        customer: `Jessica Analia`,        
      },
      {
        name: 'Cleaning fee',
        description: 'Cleaning Kia Sportage 2018 ',
        price: 31.4,
        quantity: 1,
        customer: `Paula Seling`,        
      },
    ]
  },
  {
    orderId: '8s7210sda87s1',
    date: '2018-09-25 17:32:23',
    fullName: 'Mike Thomas',
    items: [
      {
        name: 'Failed payment admin fee',
        description: 'Transaction 9547765',
        price: 12.00,
        quantity: 1,
        customer: `Jessica Clark`,        
      },
      {
        name: 'Insurance Excess',
        description: 'Insurance invoice Kia Sportange 2018',
        price: 420.5,
        quantity: 1,
        customer: `Jacko Paste`,        
      },
      {
        name: 'Petrol refill',
        description: 'Kia Sportage AB366 ',
        price: 68.8,
        quantity: 1,
        customer: `Adam Castor`,        
      },
    ]
  },
];

export default orders;