export default [
    {
        Header: 'Name',
        accessor: "Name",
        query: {
            matchingValue: 'like',
        },
        sortable: true,
        width: 150,
    },
    {
        Header: 'MSR Email',
        accessor: "Email",
        query: {
            matchingValue: 'like',
        },
        sortable: true,
        width: 150,
    },
    {
        Header: 'CRM ID',
        accessor: "CRMID",
        query: {
            field: "CRMID",
            matchingValue: 'like'
        },
        sortable: true,
        width: 150,
    },
    {
        Header: 'State',
        accessor: "State",
        width: 160,
        sortable: false,
        resizable: false,
        filterConfig: {
            type: 'select',
            values: [
                { value: '', label: 'All States' },
                { value: 'aud', label: 'AU' , disabled: 'disabled'},
                { value: 'Queensland', label: 'Queensland' },
                { value: 'Victoria', label: 'Victoria' },
                { value: 'New South Wales', label: 'New South Wales' },
                { value: 'South Australia', label: 'South Australia' },
                { value: 'Western Australia', label: 'Western Australia' },
                { value: 'Australian Capital Territory', label: 'Australian Capital Territory' },
                { value: 'ukd', label: 'UK' , disabled: 'disabled'},
                { value: 'London', label: 'London' },
                { value: 'mxd', label: 'MX' , disabled: 'disabled'},
                { value: 'Mexico', label: 'Mexico' },
                { value: 'cad', label: 'CA' , disabled: 'disabled'},
                { value: 'Ontario', label: 'Ontario' },
            ]
        },
    },
    {
        Header: 'Total Earnings',
        accessor: "Total Earnings",
        filterable: false,
        sortable: false,
    },
    {
        Header: 'Total Trips',
        accessor: "Total Trips",
        filterable: false,
        sortable: false,
    },
    {
        Header: 'Total Distance',
        accessor: "Total Distance",
        filterable: false,
        sortable: false,
    },
];
