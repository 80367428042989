import moment from 'moment';
import { error } from './notification';
import { push } from 'react-router-redux';
import member from '../services/member';

// Actions
export const SET_MEMBER = 'admin/member/SET_MEMBER';
export const LOADING_MEMBER = 'admin/member/LOADING_MEMBER';
export const LOADING_STATS = 'admin/member/LOADING_STATS';
export const EDIT_MEMBER = 'admin/member/EDIT_MEMBER';
export const SET_STATS = 'admin/member/SET_STATS';
export const SET_EXPORT_FILTERS = 'admin/member/SET_EXPORT_FILTERS';

const initial = {
  email: '',
  status: '',
  crmID: '',
  stats: [],
  dashboardInfo: {
    stats: {
      averageHourlyEarnings: null,
      averageSafetyScore: null,
      averageUberRating: null,
      drivenDistance: {inKms: null, inMiles: null}
    },
    ranking: {
      averageHourlyEarnings: null,
      averageSafetyScore: null,
      averageUberRating: null,
      totalDrivenDistance: null,
    }
  },  
};

export const INITIAL_STATE = {
  member: JSON.parse(localStorage.getItem('member')) || initial,
  loadingMember: false,
  loadingStats: false,
  exportMemberFilters: {},
};

// Reducer
export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case LOADING_STATS:
      return {
        ...state,
        loadingStats: action.payload,
      };

    case LOADING_MEMBER:
      return {
        ...state,
        loadingMember: action.payload,
      };

    case SET_MEMBER:
      const newState = {
        ...state,
        member: action.payload,
      };

      localStorage.setItem('member', JSON.stringify(newState.member));

      return newState;

    case SET_STATS:
      const newStats = {
        ...state,
        stats: action.payload,
      };

      return newStats;

    case SET_EXPORT_FILTERS:
      const exportMemberFilters = {
        ...state,
        exportMemberFilters: action.payload,
      };

      return exportMemberFilters;
    
    default:
      return state;
  }
}

// Action Creators
export function find(id) {
  return function(dispatch) {
    dispatch({ type: LOADING_MEMBER, payload: true });

    member.find(id)
      .then(({ member, aggregates, ranking }) => {
        const dashboardInfo = { stats: aggregates, ranking };
        dispatch({ type: SET_MEMBER, payload: { ...member, dashboardInfo}});
        dispatch({ type: LOADING_MEMBER, payload: false });
        dispatch(getStats({id})); 
      })
      .catch(({ response }) => {
        dispatch(error(response));

        dispatch({ type: LOADING_MEMBER, payload: false });

        dispatch(push('/members'));
      });
  };
}

export function edit(id, payload) {
  return function (dispatch) {
    member.edit(id, payload)
      .then(({ member }) => {
        dispatch({ type: EDIT_MEMBER, payload: member });
      })
      .catch(({ response }) => {
        dispatch(error(response));

        console.log('Edit Member Error', error);
      });
  };
}

export function setExportMemberFilters(payload) {
  return function (dispatch) {
    dispatch({ type: SET_EXPORT_FILTERS, payload });
  };
}

export function getStats({id, dates, extra}) {
  const sorting = extra && extra[3];

  return function (dispatch) {
    dispatch({ type: LOADING_STATS, payload: true });
    const search = {
      dir: 'asc',
      sortBy: 'day',
    };
    const startDate = dates ? dates.startDate : moment().subtract(30, 'days').format('YYYY-MM-DD');
    const endDate = dates ? dates.endDate : moment().format('YYYY-MM-DD');

    if (sorting && sorting.field) {
      search.dir = sorting.dir;
      search.sortBy = sorting.field;
    }
    
    const query = `startDate=${startDate}&endDate=${endDate}&search=${JSON.stringify(search)}`;

    dispatch(setExportMemberFilters(query));
    
    member.stats(id, query)
    .then(data => {
        dispatch({ type: SET_STATS, payload: data })
        dispatch({ type: LOADING_STATS, payload: false });
      })
      .catch(({ response }) => {
        dispatch(error(response));

        dispatch({ type: LOADING_STATS, payload: false });
      });
  }
}