import { error } from './notification';
import { push } from 'react-router-redux';
import moment from "moment";
import reports from './../services/reports';
import fileDownload from 'js-file-download';
import paygDefaultColumns from '../utils/constants/tables/payg';
import leadsSignupDefaultColumns from '../utils/constants/tables/leadsSignup';
import uberHourlyEarningsDefaultColumns from '../utils/constants/tables/uberHourlyEarnings';
import uberHourlyTripsDefaultColumns from '../utils/constants/tables/uberHourlyTrips';
import uberPermissionsDefaultColumns from '../utils/constants/tables/uberPermissions';
import weeklyTotalEarningsDefaultColumns from '../utils/constants/tables/weeklyTotalEarnings';
import weeklyTotalPaymentsDefaultColumns from '../utils/constants/tables/weeklyTotalPayments';
import weeklyAverageEarningsDefaultColumns from '../utils/constants/tables/weeklyAverageEarnings';
import weeklyKmAndEarningsDefaultColumns from '../utils/constants/tables/weeklyKmAndEarnings';
import dailyKmAndEarningsDefaultColumns from '../utils/constants/tables/dailyKmAndEarnings';


// Actions
const LOADING_LIST = 'admin/reports/LOADING_LIST';
const SET_MSR_MEMBERS = 'admin/reports/SET_MSR_MEMBERS';
const SET_MISSING_MEMBERS = 'admin/reports/SET_MISSING_MEMBERS';
const SET_CSV_PATH = 'admin/reports/SET_CSV_PATH';
const SET_PAGE = 'admin/reports/SET_PAGE';
const SET_INTERVAL = 'admin/reports/SET_INTERVAL';
const SET_COUNTRY = 'admin/reports/SET_COUNTRY';
const SET_PAYMENT_CATEGORY = 'admin/reports/SET_PAYMENT_CATEGORY';
const GET_LEADS = 'admin/reports/GET_LEADS';
const SET_PAYG = 'admin/reports/SET_PAYG';
const SET_LEADS_SIGNUP = 'admin/reports/SET_LEADS_SIGNUP';
const SET_UBER_HOURLY_TRIPS = 'admin/reports/SET_UBER_HOURLY_TRIPS';
const SET_UBER_HOURLY_EARNINGS = 'admin/reports/SET_UBER_HOURLY_EARNINGS';
const SET_UBER_PERMISSIONS = 'admin/reports/SET_UBER_PERMISSIONS'
const SET_WEEKLY_TOTAL_EARNINGS = 'admin/reports/SET_WEEKLY_TOTAL_EARNINGS';
const SET_WEEKLY_TOTAL_PAYMENTS = 'admin/reports/SET_WEEKLY_TOTAL_PAYMENTS';
const SET_WEEKLY_AVERAGE_EARNINGS = 'admin/reports/SET_WEEKLY_AVERAGE_EARNINGS';
const SET_WEEKLY_KM_AND_EARNINGS = 'admin/reports/SET_WEEKLY_KM_AND_EARNINGS';
const SET_DAILY_KM_AND_EARNINGS = 'admin/reports/SET_DAILY_KM_AND_EARNINGS';


const INITIAL_PAGE = {
  total: 0,
  currentPage: 0,
  pageSize: 100,
};

// initial state
const INITIAL_STATE = {
    reports: {
    msr: [],
    reportFile: null,
    leads: null,
    uberHourlyTrips: {
      interval: [],
      list: [],
      columns: [ ...uberHourlyTripsDefaultColumns ],
    },
    uberHourlyEarnings: {
      interval: [],
      list: [],
      columns: [ ...uberHourlyEarningsDefaultColumns ],
    },
    uberPermissions: {
      interval: [],
      list: [],
      columns: [ ...uberPermissionsDefaultColumns ],
    },
    payg: {
      interval: [],
      list: [],
      columns: [ ...paygDefaultColumns ],
    },
    leadsSignup: {
        interval: [],
        country: 'Australia',
        list: [],
        columns: [ ...leadsSignupDefaultColumns ],
    },
    weeklyTotalEarnings: {
    interval: [],
    list: [],
    columns: [ ...weeklyTotalEarningsDefaultColumns ],
    },
    weeklyTotalPayments: {
        interval: [],
        list: [],
        paymentCategory: '',
        columns: [ ...weeklyTotalPaymentsDefaultColumns ],
    },
    weeklyAverageEarnings: {
      interval: [],
      list: [],
      columns: [ ...weeklyAverageEarningsDefaultColumns ],
    },
    weeklyKmAndEarnings: {
      interval: [],
      list: [],
      columns: [ ...weeklyKmAndEarningsDefaultColumns ],
    },
    dailyKmAndEarnings: {
        interval: [],
        list: [],
        columns: [ ...dailyKmAndEarningsDefaultColumns ],
    },
  },
  from: null,
  to: null,
  page: { ...INITIAL_PAGE },
  csvPath: null,
  loading: false,
}

// Reducer
export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case LOADING_LIST:
      return {
        ...state,
        loading: action.payload,
      };

    case SET_MSR_MEMBERS:
      return {
        ...state,
        msr: action.payload,
      };

    case SET_MISSING_MEMBERS:
      return {
        ...state,
        reportFile: action.payload,
      };

    case GET_LEADS:
    return {
      ...state,
      leads: action.payload
    };

    case SET_UBER_PERMISSIONS:
      const uberPermissionsColumns = generateColumns(
          uberPermissionsDefaultColumns,
          action.payload.interval
      );

      return {
          ...state,
          reports: {
              ...state.reports,
              uberPermissions: {
                  list: action.payload.stats,
                  interval: action.payload.interval,
                  columns: uberPermissionsColumns,
              },
          }
      };

    case SET_WEEKLY_TOTAL_EARNINGS:
      const weeklyTotalEarningColumns = generateColumns(
          weeklyTotalEarningsDefaultColumns,
          action.payload.interval
      );

      return {
          ...state,
          reports: {
              ...state.reports,
              weeklyTotalEarnings: {
                  list: action.payload.members,
                  interval: action.payload.interval,
                  columns: weeklyTotalEarningColumns,
              },
          }
      };
    case SET_WEEKLY_TOTAL_PAYMENTS:
      const weeklyTotalPaymentsColumns = generateColumns(
          weeklyTotalPaymentsDefaultColumns,
          action.payload.interval
      );

      return {
          ...state,
          reports: {
              ...state.reports,
              weeklyTotalPayments: {
                  list: action.payload.members,
                  interval: action.payload.interval,
                  columns: weeklyTotalPaymentsColumns,
              },
          }
      };
    case SET_WEEKLY_AVERAGE_EARNINGS:
      const weeklyAverageEarningColumns = generateColumns(
          weeklyAverageEarningsDefaultColumns,
          action.payload.interval
      );

      return {
          ...state,
          reports: {
              ...state.reports,
              weeklyAverageEarnings: {
                  list: action.payload.members,
                  interval: action.payload.interval,
                  columns: weeklyAverageEarningColumns,
              },
          }
      };
    case SET_WEEKLY_KM_AND_EARNINGS:
      const weeklyKmAndEarningColumns = generateColumns(
          weeklyKmAndEarningsDefaultColumns,
          action.payload.interval
      );

      return {
          ...state,
          reports: {
              ...state.reports,
              weeklyKmAndEarnings: {
                  list: action.payload.members,
                  interval: action.payload.interval,
                  columns: weeklyKmAndEarningColumns,
              },
          }
      };
      case SET_DAILY_KM_AND_EARNINGS:
          const dailyKmAndEarningColumns = generateColumns(
              dailyKmAndEarningsDefaultColumns,
              action.payload.interval
          );

          return {
              ...state,
              reports: {
                  ...state.reports,
                  dailyKmAndEarnings: {
                      list: action.payload.members,
                      interval: action.payload.interval,
                      columns: dailyKmAndEarningColumns,
                  },
              }
          };
    case SET_PAYG:
      const paygColumns = generatePaygColumns(
          paygDefaultColumns,
          action.payload.interval
      );

      return {
        ...state,
        reports: {
          ...state.reports,
          payg: {
            list: action.payload.members,
            interval: action.payload.interval,
            columns: paygColumns,
          },
        }
      };

      case SET_LEADS_SIGNUP:
          return {
              ...state,
              reports: {
                  ...state.reports,
                  leadsSignup: {
                      list: action.payload,
                      columns: leadsSignupDefaultColumns,
                  },
              }
          };

    case SET_UBER_HOURLY_TRIPS:
      const tripColumns = generateColumns(
          uberHourlyTripsDefaultColumns,
          action.payload.interval
      );

      return {
        ...state,
        reports: {
          ...state.reports,
          uberHourlyTrips: {
            list: action.payload.members,
            interval: action.payload.interval,
            columns: tripColumns,
          },
        }
      };

    case SET_UBER_HOURLY_EARNINGS:
      const earningColumns = generateColumns(
          uberHourlyEarningsDefaultColumns,
          action.payload.interval
      );

      return {
        ...state,
        reports: {
          ...state.reports,
          uberHourlyEarnings: {
            list: action.payload.members,
            interval: action.payload.interval,
            columns: earningColumns,
          },
        }
      };

    case SET_CSV_PATH:
      return {
        ...state,
        csvPath: action.payload,
      };


    case SET_PAGE:
      return {
        ...state,
        page: {
          total: action.payload.total,
          currentPage: action.payload.offset / action.payload.size,
          pageSize: action.payload.size,
        },
      };

    case SET_INTERVAL:
      return {
        ...state,
        from: action.payload.from,
        to: action.payload.to,
      };

    case SET_COUNTRY:
      return {
          ...state,
          country: action.payload.country,
      };

    case SET_PAYMENT_CATEGORY:
      return {
          ...state,
          paymentCategory: action.payload.paymentCategory,
      };

    default:
      return state;
  }
}

function generateColumns(defaults, interval) {
  const extra = interval.slice(0, 168).map(date => ({
    Header: date,
    id: date,
    filterable: false,
    sortable: false,
    width: 150,
    accessor: row => row.Stats[date] || 0,
  }));
  return [...defaults,...extra];
};

function generatePaygColumns(defaults, interval) {
  let extra = [];

  if (interval['days']) {
    extra = interval['days'].map(date => ({
      Header: date,
      id: date,
      filterable: false,
      sortable: false,
      accessor: row => row.Stats['days'][date] || 0,
    }));
  }

  if (interval['weeks']) {
    extra =  extra.concat(interval['weeks'].map(date => ({
      Header: date,
      id: date,
      filterable: false,
      sortable: false,
      accessor: row => row.Stats['weeks'][date] || 0,
    })));
  }

  return [...defaults,...extra];
};

export function setCsvPath(payload) {
  return function (dispatch) {
    dispatch({ type: SET_CSV_PATH, payload });
  };
}

export function setInterval(payload) {
  return function (dispatch) {
    dispatch({ type: SET_INTERVAL, payload });
  };
}

export function setCountry(payload) {
    return function (dispatch) {
        dispatch({ type: SET_COUNTRY, payload });
    };
}

export function setPaymentCategory(payload) {
    return function (dispatch) {
        dispatch({ type: SET_PAYMENT_CATEGORY, payload });
    };
}

// Action Creators
export function getMsrMembers() {
  return function (dispatch) {
    dispatch({ type: LOADING_LIST, payload: true });

    reports.msr()
      .then(({ members }) => {
        dispatch({ type: SET_MSR_MEMBERS, payload: members });

        dispatch({ type: LOADING_LIST, payload: false });
      })
      .catch(({ response }) => {
        dispatch(error(response));
        dispatch({ type: LOADING_LIST, payload: false });
        dispatch(push('/reports'));
      });
  }
}

export function getMissingMembers(payload) {
  return function (dispatch) {
    dispatch({ type: LOADING_LIST, payload: true });

    reports.missing(payload)
      .then((filepath) => {
        dispatch({ type: SET_MISSING_MEMBERS, payload: filepath });
        dispatch({ type: LOADING_LIST, payload: false });
      })
      .catch(({ response }) => {
        dispatch(error(response));
        dispatch({ type: LOADING_LIST, payload: false });
        dispatch(push('/reports'));
      });
  }
}

export function resetLeads () {
  return function(dispatch) {
    dispatch({ type: GET_LEADS, payload: null });
  }
}

export function getLeadsReport (payload) {
  return function(dispatch) {
    dispatch({ type: LOADING_LIST, payload: true });

    reports.leads(payload)
    .then(response => {
      dispatch({ type: LOADING_LIST, payload: false });
      dispatch({ type: GET_LEADS, payload: response });
    })
    .catch(({ response }) => {
      dispatch(error(response));
      dispatch({ type: LOADING_LIST, payload: false });
      dispatch(push('/reports'));
    });
  }
}

export function resetUberPermissions () {
    return function(dispatch) {
        dispatch({ type: SET_UBER_PERMISSIONS, payload: { stats: [], interval: [] } });
    }
}

export function resetWeeklyTotalEarnings () {
    return function(dispatch) {
        dispatch({ type: SET_WEEKLY_TOTAL_EARNINGS, payload: { members: [], interval: [] } });
    }
}

export function resetWeeklyTotalPayments () {
    return function(dispatch) {
        dispatch({ type: SET_WEEKLY_TOTAL_PAYMENTS, payload: { members: [], interval: [] } });
    }
}

export function resetWeeklyAverageEarnings () {
    return function(dispatch) {
        dispatch({ type: SET_WEEKLY_AVERAGE_EARNINGS, payload: { members: [], interval: [] } });
    }
}

export function resetWeeklyKmAndEarnings () {
    return function(dispatch) {
        dispatch({ type: SET_WEEKLY_KM_AND_EARNINGS, payload: { members: [], interval: [] } });
    }
}

export function resetDailyKmAndEarnings () {
    return function(dispatch) {
        dispatch({ type: SET_DAILY_KM_AND_EARNINGS, payload: { members: [], interval: [] } });
    }
}

export function resetPayg () {
  return function(dispatch) {
    dispatch({ type: SET_PAYG, payload: { members: [], interval: [] } });
  }
}

export function resetLeadsSignup () {
    return function(dispatch) {
        dispatch({ type: SET_LEADS_SIGNUP, payload: [] });
    }
}

export function resetHourlyTrips () {
  return function(dispatch) {
    dispatch({ type: SET_UBER_HOURLY_TRIPS, payload: { members: [], interval: [] } });
  }
}

export function resetHourlyEarnings () {
  return function(dispatch) {
    dispatch({ type: SET_UBER_HOURLY_EARNINGS, payload: { members: [], interval: [] } });
  }
}

export function searchUberPermissions() {
    return function(dispatch, getState) {
        const { from, to,  loading } = getState().reports;

        if (loading) {
            return;
        }

        dispatch({ type: LOADING_LIST, payload: true });

        reports.searchUberPermissions(from, to)
            .then(({ data, meta }) => {
                dispatch({ type: SET_UBER_PERMISSIONS, payload: data });
                dispatch({ type: SET_PAGE, payload: meta });
                dispatch({ type: LOADING_LIST, payload: false });
            })
            .catch(({ response }) => {
                dispatch(error(response));

                dispatch({ type: LOADING_LIST, payload: false });
            });
    };
}

export function getUberPermissionsCSVReport(uberStatus) {
    return function(dispatch, getState) {
        const { from, to, loading } = getState().reports;

        if (loading) {
            return;
        }

        dispatch({ type: LOADING_LIST, payload: true });

        reports.uberPermissionsCSV(from, to, uberStatus)
            .then(({ filePath }) => {
                dispatch({type: LOADING_LIST, payload: false});

                dispatch(setCsvPath(filePath));

                reports.downloadCSV(filePath)
                    .then(data => {
                        fileDownload(data, `${uberStatus}-uber-permissions-${from}-${to}.csv`);
                    })
            })
            .catch(err => {
                dispatch(error(err));
                dispatch({ type: LOADING_LIST, payload: false });
            });
    }
}

export function searchWeeklyTotalEarnings(page = 0, pageSize = 100, filters = {}, sorting = {}) {
    return function(dispatch, getState) {
        const { from,to, loading } = getState().reports;

        if (loading) {
            return;
        }

        dispatch({ type: LOADING_LIST, payload: true });

        reports.searchWeeklyTotalEarnings(from, to, page, pageSize, filters, sorting)
            .then(({ data, meta }) => {
                dispatch({ type: SET_WEEKLY_TOTAL_EARNINGS, payload: data });
                dispatch({ type: SET_PAGE, payload: meta });
                dispatch({ type: LOADING_LIST, payload: false });
            })
            .catch(({ response }) => {
                dispatch(error(response));

                dispatch({ type: LOADING_LIST, payload: false });
            });
    };
}

export function getWeeklyTotalEarningsCSVReport(filters = {}, sorting = {}) {
    return function(dispatch, getState) {
        const { from, to, loading } = getState().reports;

        if (loading) {
            return;
        }

        dispatch({ type: LOADING_LIST, payload: true });

        reports.weeklyTotalEarningsCSV(from, filters, sorting)
            .then(({ filePath }) => {
                dispatch({type: LOADING_LIST, payload: false});

                dispatch(setCsvPath(filePath));

                reports.downloadCSV(filePath)
                    .then(data => {
                        fileDownload(data, `weekly-total-earnings-${from}-${to}.csv`);
                    })
            })
            .catch(err => {
                dispatch(error(err));
                dispatch({ type: LOADING_LIST, payload: false });
            });
    }
}

export function searchWeeklyTotalPayments(page = 0, pageSize = 100, filters = {}, sorting = {}) {
    return function(dispatch, getState) {
        const { from,to, paymentCategory, loading } = getState().reports;

        if (loading) {
            return;
        }

        dispatch({ type: LOADING_LIST, payload: true });

        reports.searchWeeklyTotalPayments(from, to, paymentCategory, page, pageSize, filters, sorting)
            .then(({ data, meta }) => {
                dispatch({ type: SET_WEEKLY_TOTAL_PAYMENTS, payload: data });
                dispatch({ type: SET_PAGE, payload: meta });
                dispatch({ type: LOADING_LIST, payload: false });
            })
            .catch(({ response }) => {
                dispatch(error(response));

                dispatch({ type: LOADING_LIST, payload: false });
            });
    };
}

export function getWeeklyTotalPaymentsCSVReport(filters = {}, sorting = {}) {
    return function(dispatch, getState) {
        const { from, to, paymentCategory, loading } = getState().reports;

        if (loading) {
            return;
        }

        dispatch({ type: LOADING_LIST, payload: true });

        reports.weeklyTotalPaymentsCSV(from, to, paymentCategory, filters, sorting)
            .then(({ filePath }) => {
                dispatch({type: LOADING_LIST, payload: false});

                dispatch(setCsvPath(filePath));

                reports.downloadCSV(filePath)
                    .then(data => {
                        fileDownload(data, `weekly-total-payments-${paymentCategory}-${from}-${to}.csv`);
                    })
            })
            .catch(err => {
                dispatch(error(err));
                dispatch({ type: LOADING_LIST, payload: false });
            });
    }
}

export function searchWeeklyAverageEarnings(page = 0, pageSize = 100, filters = {}, sorting = {}) {
    return function(dispatch, getState) {
        const { from,to, loading } = getState().reports;

        if (loading) {
            return;
        }

        dispatch({ type: LOADING_LIST, payload: true });

        reports.searchWeeklyAverageEarnings(from, to, page, pageSize, filters, sorting)
            .then(({ data, meta }) => {
                dispatch({ type: SET_WEEKLY_AVERAGE_EARNINGS, payload: data });
                dispatch({ type: SET_PAGE, payload: meta });
                dispatch({ type: LOADING_LIST, payload: false });
            })
            .catch(({ response }) => {
                dispatch(error(response));

                dispatch({ type: LOADING_LIST, payload: false });
            });
    };
}

export function searchWeeklyKmAndEarnings(page = 0, pageSize = 100, filters = {}, sorting = {}) {
    return function(dispatch, getState) {
        const { from,to, loading } = getState().reports;

        if (loading) {
            return;
        }

        dispatch({ type: LOADING_LIST, payload: true });

        reports.searchWeeklyKmAndEarnings(from, to, page, pageSize, filters, sorting)
            .then(({ data, meta }) => {
                dispatch({ type: SET_WEEKLY_KM_AND_EARNINGS, payload: data });
                dispatch({ type: SET_PAGE, payload: meta });
                dispatch({ type: LOADING_LIST, payload: false });
            })
            .catch(({ response }) => {
                dispatch(error(response));

                dispatch({ type: LOADING_LIST, payload: false });
            });
    };
}

export function searchDailyKmAndEarnings(page = 0, pageSize = 100, filters = {}, sorting = {}) {
    return function(dispatch, getState) {
        const { from,to, loading } = getState().reports;

        if (loading) {
            return;
        }

        dispatch({ type: LOADING_LIST, payload: true });

        reports.searchDailyKmAndEarnings(from, to, page, pageSize, filters, sorting)
            .then(({ data, meta }) => {
                dispatch({ type: SET_DAILY_KM_AND_EARNINGS, payload: data });
                dispatch({ type: SET_PAGE, payload: meta });
                dispatch({ type: LOADING_LIST, payload: false });
            })
            .catch(({ response }) => {
                dispatch(error(response));

                dispatch({ type: LOADING_LIST, payload: false });
            });
    };
}

export function getWeeklyAverageEarningsCSVReport(filters = {}, sorting = {}) {
    return function(dispatch, getState) {
        const { from, to, loading } = getState().reports;

        if (loading) {
            return;
        }

        dispatch({ type: LOADING_LIST, payload: true });

        reports.weeklyAverageEarningsCSV(from, filters, sorting)
            .then(({ filePath }) => {
                dispatch({type: LOADING_LIST, payload: false});

                dispatch(setCsvPath(filePath));

                reports.downloadCSV(filePath)
                    .then(data => {
                        fileDownload(data, `weekly-average-earnings-${from}-${to}.csv`);
                    })
            })
            .catch(err => {
                dispatch(error(err));
                dispatch({ type: LOADING_LIST, payload: false });
            });
    }
}

export function getWeeklyKmAndEarningsCSVReport(filters = {}, sorting = {}) {
    return function(dispatch, getState) {
        const { from, to, loading } = getState().reports;

        if (loading) {
            return;
        }

        dispatch({ type: LOADING_LIST, payload: true });

        reports.weeklyKmAndEarningsCSV(from, filters, sorting)
            .then(({ filePath }) => {
                dispatch({type: LOADING_LIST, payload: false});

                dispatch(setCsvPath(filePath));

                reports.downloadCSV(filePath)
                    .then(data => {
                        fileDownload(data, `weekly-km-and-earnings-${from}-${to}.csv`);
                    })
            })
            .catch(err => {
                dispatch(error(err));
                dispatch({ type: LOADING_LIST, payload: false });
            });
    }
}

export function getDailyKmAndEarningsCSVReport(filters = {}, sorting = {}) {
    return function(dispatch, getState) {
        const { from, to, loading } = getState().reports;

        if (loading) {
            return;
        }

        dispatch({ type: LOADING_LIST, payload: true });

        reports.dailyKmAndEarningsCSV(from, filters, sorting)
            .then(({ filePath }) => {
                dispatch({type: LOADING_LIST, payload: false});

                dispatch(setCsvPath(filePath));

                reports.downloadCSV(filePath)
                    .then(data => {
                        fileDownload(data, `daily-km-and-earnings-${from}-${to}.csv`);
                    })
            })
            .catch(err => {
                dispatch(error(err));
                dispatch({ type: LOADING_LIST, payload: false });
            });
    }
}

export function searchPayg(page = 0, pageSize = 100, filters = {}, sorting = {}) {
  return function(dispatch, getState) {
    const { from, loading } = getState().reports;

    if (loading) {
      return;
    }

    dispatch({ type: LOADING_LIST, payload: true });

    reports.searchPayg(from, page, pageSize, filters, sorting)
        .then(({ data, meta }) => {
          dispatch({ type: SET_PAYG, payload: data });
          dispatch({ type: SET_PAGE, payload: meta });
          dispatch({ type: LOADING_LIST, payload: false });
        })
        .catch(({ response }) => {
          dispatch(error(response));

          dispatch({ type: LOADING_LIST, payload: false });
        });
  };
}

export function getPaygCSVReport(filters = {}, sorting = {}) {
  return function(dispatch, getState) {
    const { from, loading } = getState().reports;

    if (loading) {
      return;
    }

    dispatch({ type: LOADING_LIST, payload: true });

    reports.paygCSV(from, filters, sorting)
        .then(({ filePath }) => {
          dispatch({type: LOADING_LIST, payload: false});

          dispatch(setCsvPath(filePath));

          reports.downloadCSV(filePath)
              .then(data => {
                fileDownload(data, `payg-${from}-${moment()}.csv`);
              })
        })
        .catch(err => {
          dispatch(error(err));
          dispatch({ type: LOADING_LIST, payload: false });
        });
  }
}

export function searchLeadsSignup(page = 0, pageSize = 100, filters = {}, sorting = {}) {
    return function(dispatch, getState) {
        const { from, to, country, loading } = getState().reports;

        if (loading) {
            return;
        }

        dispatch({ type: LOADING_LIST, payload: true });

        // Reset table for performance
        dispatch({ type: SET_LEADS_SIGNUP, payload: [] });
        dispatch({ type: SET_PAGE, payload: { ...INITIAL_PAGE } });

        reports.searchLeadsSignup(from, to, country, page, pageSize, filters, sorting)
            .then(({ data, meta }) => {
                dispatch({ type: SET_LEADS_SIGNUP, payload: data });
                dispatch({ type: SET_PAGE, payload: meta });
                dispatch({ type: LOADING_LIST, payload: false });
            })
            .catch(({ response }) => {
                dispatch(error(response));

                dispatch({ type: LOADING_LIST, payload: false });
            });
    };
}

export function getLeadsSignupCSVReport(filters = {}, sorting = {}) {
    return function(dispatch, getState) {
        const { from, to, country, loading } = getState().reports;

        if (loading) {
            return;
        }

        dispatch({ type: LOADING_LIST, payload: true });

        reports.leadsSignupCSV(from, to, country, filters, sorting)
            .then(({ filePath }) => {
                dispatch({type: LOADING_LIST, payload: false});

                dispatch(setCsvPath(filePath));

                reports.downloadCSV(filePath)
                    .then(data => {
                        fileDownload(data, `leads-signup-${from}-${to}-${country}.csv`);
                    })
            })
            .catch(err => {
                dispatch(error(err));
                dispatch({ type: LOADING_LIST, payload: false });
            });
    }
}

export function searchHourlyEarnings(page = 0, pageSize = 100, filters = {}, sorting = {}) {
    return function(dispatch, getState) {
        const { from, to, loading } = getState().reports;

        if (loading) {
            return;
        }

        dispatch({ type: LOADING_LIST, payload: true });

        // Reset table for performance
        dispatch({ type: SET_UBER_HOURLY_EARNINGS, payload: { interval: [], members: [] } });
        dispatch({ type: SET_PAGE, payload: { ...INITIAL_PAGE } });

        reports.searchHourlyEarnings(from, to, page, pageSize, filters, sorting)
            .then(({ data, meta }) => {
                dispatch({ type: SET_UBER_HOURLY_EARNINGS, payload: data });
                dispatch({ type: SET_PAGE, payload: meta });
                dispatch({ type: LOADING_LIST, payload: false });
            })
            .catch(({ response }) => {
                dispatch(error(response));

                dispatch({ type: LOADING_LIST, payload: false });
            });
    };
}

export function getHourlyEaringsCSVReport(filters = {}, sorting = {}) {
  return function(dispatch, getState) {
    const { from, to, loading } = getState().reports;

    if (loading) {
      return;
    }

    dispatch({ type: LOADING_LIST, payload: true });

    reports.hourlyEarningsCSV(from, to, filters, sorting)
        .then(({ filePath }) => {
          dispatch({type: LOADING_LIST, payload: false});

          dispatch(setCsvPath(filePath));

          reports.downloadCSV(filePath)
              .then(data => {
                fileDownload(data, `uber-hourly-earnings-${from}-${to}.csv`);
              })
        })
        .catch(err => {
          dispatch(error(err));
          dispatch({ type: LOADING_LIST, payload: false });
        });
  }
}

export function searchHourlyTrips(page = 0, pageSize = 100, filters = {}, sorting = {}) {
  return function(dispatch, getState) {
    const { from, to, loading } = getState().reports;

    if (loading) {
      return;
    }

    dispatch({ type: LOADING_LIST, payload: true });

    // Reset table for performance
    dispatch({ type: SET_UBER_HOURLY_TRIPS, payload: { interval: [], members: [] } });
    dispatch({ type: SET_PAGE, payload: { ...INITIAL_PAGE } });

    reports.searchHourlyTrips(from, to, page, pageSize, filters, sorting)
        .then(({ data, meta }) => {
          dispatch({ type: SET_UBER_HOURLY_TRIPS, payload: data });
          dispatch({ type: SET_PAGE, payload: meta });
          dispatch({ type: LOADING_LIST, payload: false });
        })
        .catch(({ response }) => {
          dispatch(error(response));

          dispatch({ type: LOADING_LIST, payload: false });
        });
  };
}

export function getHourlyTripsCSVReport(filters = {}, sorting = {}) {
  return function(dispatch, getState) {
    const { from, to, loading } = getState().reports;

    if (loading) {
      return;
    }

    dispatch({ type: LOADING_LIST, payload: true });

    reports.hourlyTripsCSV(from, to, filters, sorting)
        .then(({ filePath }) => {
          dispatch({type: LOADING_LIST, payload: false});

          dispatch(setCsvPath(filePath));

          reports.downloadCSV(filePath)
              .then(data => {
                fileDownload(data, `uber-hourly-trips-${from}-${to}.csv`);
              })
        })
        .catch(err => {
          dispatch(error(err));
          dispatch({ type: LOADING_LIST, payload: false });
        });
  }
}

export function exportMemberCSV() {

  return function(dispatch, getState) {
    const membersState = getState().member;
    const id = membersState.member.id;
    const exportMemberFilters = membersState.exportMemberFilters;

    dispatch({ type: LOADING_LIST, payload: true });

    reports.exportMemberCSV(id, exportMemberFilters)
      .then(({ filePath }) => {
        dispatch(setCsvPath(filePath));
        dispatch({ type: LOADING_LIST, payload: false });
        reports.downloadCSV(filePath)
          .then(data => {
            fileDownload(data, `member-${id}-stats.csv`);
          })
      })
      .catch(err => {
        dispatch(error(err));
        dispatch({ type: LOADING_LIST, payload: false });
      });
  };
}

export function exportMembersCSV() {

  return function(dispatch, getState) {
    const membersState = getState().members;
    const exportMembersFilters = membersState.exportMembersFilters;

    dispatch({ type: LOADING_LIST, payload: true });

    reports.exportMembersCSV({ ...exportMembersFilters })
      .then(({ filePath }) => {
        dispatch(setCsvPath(filePath));
        dispatch({ type: LOADING_LIST, payload: false });

        reports.downloadCSV(filePath)
          .then(data => {
            fileDownload(data, 'membersReport.csv');
          })
      })
      .catch(err => {
        dispatch(error(err));
        dispatch({ type: LOADING_LIST, payload: false });
      });
  };
}
