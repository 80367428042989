export const CLEAR = 'admin/notifications/CLEAR';
export const ERROR = 'admin/notifications/ERROR';
export const INFO = 'admin/notifications/INFO';
export const SUCCESS = 'admin/notifications/SUCCESS';

export const INITIAL_STATE = null;

export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case ERROR:
    case INFO:
    case SUCCESS:
      return action.payload;

    case CLEAR:
      return null;

    default:
      return state;
  }
}

export function clear() {
  return { type: CLEAR };
}
// eslint-disable-next-line
export function error(message = 'Something went wrong. ¯\_(ツ)_/¯') {
  return { type: ERROR, payload: { type: 'error', message }};
}

export function info(message = 'Ok') {
  return { type: INFO, payload: { type: 'info', message }};
}

export function success(message = 'Success') {
  return { type: SUCCESS, payload: { type: 'success', message }};
}
