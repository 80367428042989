import Noty from 'noty';

import './notification.css';

class Notification {
  static handle({ type, text, timeout = 3000 }) {
    new Noty({
      type,
      text,
      timeout,
    }).show();
  }

  static error(text) {
    Notification.handle({ type: 'error', text });
  }

  static info(text) {
    Notification.handle({ type: 'info', text });
  }

  static warning(text) {
    Notification.handle({ type: 'warning', text });
  }

  static success(text) {
    Notification.handle({ type: 'success', text });
  }
}

Notification.displayName = 'Components/Notification';

export default Notification;
