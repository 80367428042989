import moment from 'moment';
import { error } from './notification';
import consistencyCheck from './../services/consistencyCheck';

// Actions
const SET_CHECK_ID = 'admin/consistencyCheck/SET_CHECK_ID';
const SET_ISSUES = 'admin/consistencyCheck/SET_ISSUES';
const SET_STATS = 'admin/consistencyCheck/SET_STATS';
const SET_PAGE = 'admin/consistencyCheck/SET_PAGE';
const LOADING_LIST = 'admin/consistencyCheck/LOADING_LIST';

const INITIAL_STATE = {
  loading: false,
  checkId: null,
  issues: [],
  stats: {
    stats: [],
    country: '',
    createdAt: null,
  },
  page: {
    total: 0,
    currentPage: 0,
    pageSize: 100,
  },
};

// Reducer
export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case LOADING_LIST:
      return { ...state, loading: action.payload };

    case SET_CHECK_ID:
      return { ...state, checkId: action.payload };

    case SET_ISSUES:
      return { ...state, issues: action.payload };

    case SET_STATS:
      return { ...state, stats: action.payload };

    case SET_PAGE:
      return {
        ...state,
        page: {
          total: action.payload.total,
          currentPage: action.payload.offset / action.payload.size,
          pageSize: action.payload.size,
        },
      };

    default:
      return state;
  }
}

export function setId(id) {
  return function (dispatch) {
    dispatch({ type: SET_CHECK_ID, payload: id });

    dispatch(search());
  };
}

export function search(page = 0, pageSize = 100, filters = [], sorting = []) {
  return function(dispatch, getState) {
    dispatch({ type: LOADING_LIST, payload: true });

    const id = getState().consistencyCheck.checkId;

    consistencyCheck.search(id, page, pageSize, filters, sorting)
      .then(({ data, meta }) => {
        dispatch({ type: SET_ISSUES, payload: data });
        dispatch({ type: SET_PAGE, payload: meta });
        dispatch({ type: LOADING_LIST, payload: false });
      })
      .catch(({ response }) => {
        dispatch(error(response));

        dispatch({ type: LOADING_LIST, payload: false });
      });
  };
}

export function getStats() {
  return function(dispatch, getState) {
    dispatch({ type: LOADING_LIST, payload: true });

    const id = getState().consistencyCheck.checkId;

    consistencyCheck.getStats(id)
      .then(response => {
        dispatch({ type: SET_STATS, payload: response });
      })
      .catch(({ response }) => {
        dispatch(error(response));

        dispatch({ type: LOADING_LIST, payload: false });
      });
  };
}
