import api from '../utils/api';

const proxy = ({ data }) => data;

const find = id => api.get(`/members/${id}`).then(proxy);
const edit = (id, payload) => api.patch(`/members/${id}`, payload).then(proxy);
// const passwordReset = payload => api.post('/members/password-reset', payload).then(proxy);
const stats = (id, payload) => api.get(`/members/${id}/stats?${payload}`).then(proxy);

export default {
  find,
  edit,
  stats,
  // reset: passwordReset,
}
