import React from 'react';
import { NavLink } from 'react-router-dom'
import { Menu } from 'semantic-ui-react'

import './aside.css'

class Aside extends React.Component {
  render() {
    const { user } = this.props;

    if (!user) return null;

    return (
      <aside>
        <Menu pointing secondary vertical>
          <Menu.Item name="dashboard" as={NavLink} exact to="/">Dashboard</Menu.Item>
          <Menu.Item name="campaigns" as={NavLink} exact to="/campaigns">Campaigns</Menu.Item>
          <Menu.Item name="members" as={NavLink} to="/members">Members</Menu.Item>
          <Menu.Item name="vehicles" as={NavLink} to="/vehicles">Vehicles</Menu.Item>
          <Menu.Item name="reports" as={NavLink} to="/reports">Reports</Menu.Item>
          <Menu.Item name="login-logs" as={NavLink} to="/audit/log-attempts/">Login Logs</Menu.Item>
          <Menu.Item name="reset-password-log" as={NavLink} to="/audit/reset-password-log-attempts/">Reset Password Logs</Menu.Item>
          <Menu.Item name="packages" as={NavLink} to="/packages/">Packages</Menu.Item>
          <Menu.Item name="consistency-checks" as={NavLink} to="/consistency-checks/">Data Diagnostics</Menu.Item>
          {/*<Menu.Item name='tickets' as={NavLink} to='/zendesk-ticket'>Tickets</Menu.Item>*/}
          {/* <Menu.Item name='payments'>
            Payments
            <Menu.Menu>
              <Menu.Item name='payments-history' as={NavLink} to='/payments/history'>&bull; History</Menu.Item>
              <Menu.Item name='payments-batch' as={NavLink} to='/payments/batch'>&bull; Batch</Menu.Item>
              <Menu.Item name='payments-orders' as={NavLink} to='/payments/orders'>&bull; Orders</Menu.Item>
              <Menu.Item name='payments-customers' as={NavLink} to='/payments/customers'>&bull; Customers</Menu.Item>
            </Menu.Menu>
          </Menu.Item> */}
        </Menu>
      </aside>
    );
  }
}

Aside.displayName = 'Components/Aside';

export default Aside;
