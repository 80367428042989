import moment from 'moment';
import get from 'lodash/get';
import { getDashboard } from './stats';
import loginServices from './../services/login';
import Notification from './../components/Notification/Notification';

// Actions
export const AUTH_SUCCESS = 'admin/login/SUCCESS';
export const AUTH_FAILED = 'admin/login/FAILED';
export const AUTH_LOGOUT = 'admin/login/LOGOUT';
export const GET_TOKENS = 'admin/login/GET_TOKENS';
export const SET_TOKENS = 'admin/login/SET_TOKENS';
export const SET_USER = 'admin/login/SET_USER';
export const SET_LOADING_SESSION = 'admin/login/SET_LOADING_SESSION';

// initial state
export const INITIAL_STATE = {
  user: JSON.parse(localStorage.getItem('user')) || { country: 'au' },
  tokens: JSON.parse(localStorage.getItem('tokens')) || null,
  isAuth: JSON.parse(localStorage.getItem('isAuth')) || false,
  loadingSession: false,
  errors: null,
}

// Reducer
export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case AUTH_SUCCESS:
      localStorage.setItem('isAuth', JSON.stringify(true));

      Notification.success('Welcome back.');

      return {
        ...state,
        isAuth: true,
      };

    case AUTH_LOGOUT:
      localStorage.clear();

      Notification.info('Successfully logged out.');

      return {
        isAuth: false,
        user: null,
        tokens: null,
      };

    case AUTH_FAILED:
      const { payload } = action;
      let errors = state.errors;
      const status = get(payload, 'response.status');

      localStorage.clear();

      // There are cases where axios cannot connect to the server
      // to get the status and throws a generic Network Error
      if (!status || status >= 400) {
        errors = status;
        // eslint-disable-next-line
        Notification.error('Something went wrong. ¯\_(ツ)_/¯');
      } else if (payload && payload === 'logout') {
        errors = null;

        Notification.info('Successfuly logged out.');
      }

      return {
        ...state,
        isAuth: false,
        user: null,
        tokens: null,
        errors,
      };

    case GET_TOKENS:
      return state.tokens;

    case SET_TOKENS:
      const tokensObj = {
        ...state,
        tokens: {
          ...state.tokens,
          ...action.payload
        },
      };

      localStorage.setItem('tokens', JSON.stringify(tokensObj.tokens))

      return tokensObj;

    case SET_USER:
      const userObj = {
        ...state,
        user: {...state.user, ...action.payload},
      };

      localStorage.setItem('user', JSON.stringify(userObj.user));

      return userObj;

    case SET_LOADING_SESSION:
      return {
        ...state,
        loadingSession: action.payload
      };

    default:
      return state;
  }
}

// Action Creators
export function onSuccess() {
  return { type: AUTH_SUCCESS };
}

export function onLogout() {
  return { type: AUTH_LOGOUT };
}

export function onFailure(error) {
  return { type: AUTH_FAILED, payload: error };
}

export function setUser(payload) {
  return { type: SET_USER, payload };
}

export function getTokens() {
  return { type: GET_TOKENS };
}

export function setTokens(payload) {
  return { type: SET_TOKENS, payload };
}

export function getSession(token) {
  const tokens = JSON.parse(localStorage.getItem('tokens'));

  return function (dispatch) {
    const access_token = get(tokens, 'google.access_token', token);

    if (!access_token) {
      return;
    }

    loginServices.session(access_token)
      .then(response => {
        const session = {
          ...response,
          expires_at: moment().add(response.expires_in, 'seconds').format(),
        };

        dispatch(setTokens({ session }));

        dispatch(getCurrentUser());

        dispatch(getDashboard());
        // dispatch(status());
        // dispatch(signups());
        // dispatch(earnings());

        dispatch(onSuccess());
      })
      .catch(error => dispatch(onFailure(error)));
  };
}

export function refreshSession(token) {
  const tokens = JSON.parse(localStorage.getItem('tokens'));

  return function (dispatch) {
    const { access_token, expires_at } = tokens.session || {};

    if (access_token && moment(expires_at).isAfter(new Date())) {
      return;
    }

    const refreshToken = get(tokens, 'session.refresh_token', token);

    if (!refreshToken) {
      return;
    }

    dispatch({
      type: SET_LOADING_SESSION,
      payload: true,
    });

    loginServices.refresh(refreshToken)
      .then((response) => {
        dispatch(setTokens({ session: response }));

        dispatch({
          type: SET_LOADING_SESSION,
          payload: false,
        });
      })
      .catch(error => dispatch(onFailure(error)));
  };
}

export function getCurrentUser() {
  return function (dispatch) {
    loginServices.me()
      .then(response => dispatch(setUser(response)))
      .catch(error => dispatch(onFailure(error)));
  };
}
