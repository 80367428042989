import get from 'lodash/get';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { onLogout, refreshSession } from '../../modules/login';

import Header from '../../components/Header/Header';
import Aside from '../../components/Aside/Aside';
import {Modal, Dimmer, Loader } from 'semantic-ui-react'

import './layout.css';

class Layout extends Component {
  constructor(props) {
    super(props) ;

    this.state = {
      open: true,
      selectedCountry: ''
    }
  }

  componentDidMount() {
    return this.checkSession(this.props);
  }

  componentWillReceiveProps(nextProps) {
    return this.checkSession(nextProps);
  }

  checkSession = (props) => {
    const { router } = this.props;
    const { tokens, history } = props;
    const { location } = history;
    const notLogin = location && !location.pathname.includes('login');

    const isPublic = location && ['/zendesk-ticket'].includes(location.pathname);
    const sessionToken = get(tokens, 'session.access_token');

    const pathChanged = router && location ? router.location.pathname !== location.pathname : false;
    const tokenChanged = sessionToken ? sessionToken !== get(this.props, 'tokens.session.access_token') : false;
    this.setState({ pathChanged, tokenChanged });

    if (isPublic) return;

    if (!sessionToken && notLogin) {
      return this.handleRedirect('/login');
    } else if (sessionToken && !notLogin) {
      return this.handleRedirect();
    } else if (sessionToken && pathChanged) {
      this.props.refreshSession();
    }
  }

  closeModal = () => {
    this.setState({ open: false });
  }

  renderLoginErrors = () => {
    const { errors } = this.props;
    const { open } = this.state;

    let errorMessage = '';
    if (errors) {
      switch(errors) {
        case 401:
        errorMessage = 'User is unauthorized';
        break;
        case 500:
        errorMessage = 'Internal Server Error';
        break;
        default:
        errorMessage = 'General error';
        break;
      }
      return (
        <Modal
          open={open}
          header='Session error'
          content={errorMessage}
          closeIcon
          onClose={this.closeModal}
          closeOnDimmerClick={true}
        />
      )
    }

    return null;
  }

  handleRedirect = (to) => {
    const { history, location } = this.props;
    const fromPage = location && !location.pathname.includes('login') ? location.pathname : null;

    history.push(to || fromPage || '/');
  }

  renderModule = () => {
    const { children, history, loading } = this.props;
    const { location } = history;
    const isLogin = location && location.pathname === '/login';
    const loader = (
      <div className='loader'>
        <Dimmer active>
          <Loader>Checking Session</Loader>
        </Dimmer>
      </div>
    );

    return (!loading || isLogin) ? children : loader;
  }

  render() {
    const { user, tokens } = this.props;
    const auth = user && get(tokens, 'session.access_token');
    const { location } = this.props.history;
    const loginClass = location && location.pathname === '/login' ? ' hasLogin' : '';

    const header = <Header key='header' user={user} onLogout={this.props.onLogout} onCountry={value => this.setState({selectedCountry: value})} selectedCountry={this.state.selectedCountry} />;
    const aside = <Aside user={user} />;

    return ([
      !auth ? null : header,
      <div key='component' className={`layout${loginClass}`}>
        {!auth ? null : aside}
        <div className='component'>
          {this.renderModule()}
        </div>
      </div>,
      this.renderLoginErrors()
    ]);
  }
}

Layout.displayName = 'Containers/Layout'

Layout.defaultProps = {
  history: {
    push: () => {},
    location: {
      pathname: ''
    }
  }
}

const storeState = state => ({
  user: state.login.user,
  errors: state.login.errors,
  tokens: state.login.tokens,
  loading: state.login.loadingSession,
  router: state.router,
})

const storeActions = {
  onLogout,
  refreshSession
}

export default connect(storeState, storeActions)(Layout);
