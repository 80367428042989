import React from 'react';
import './loading.css';

function Loading({error, timedOut, pastDelay, retry, message}) {
  if (error) {
    return <div className='loading error'>Ups, loading error! <button onClick={ retry }>Retry</button></div>;
  } else if (timedOut) {
    return <div className='loading timeout'>Loading is taking to long... <button onClick={ retry }>Retry</button></div>;
  } else if (pastDelay) {
    return <div className='loading'>Loading...</div>;
  } else if (message) {
    return <div className='loading'>{message}</div>;
  } else {
    return <div className='loading'>Loading...</div>;
  }
}

Loading.displayName = 'Components/Loading'

export default Loading;
