export default [
    {
        Header: 'Member ID',
        accessor: "Internal id",
        filterable: false,
        sortable: true,
        width: 150,

        query: {
            field: "Member ID",
            matchingValue: 'like',
        },
    },
    {
        Header: 'Country',
        accessor: "Country",
        width: 160,
        sortable: false,
        resizable: false,
        filterConfig: {
            type: 'select',
            values: [
                { value: '', label: 'All Countries' },
                { value: 'Australia', label: 'Australia' },
                { value: 'Mexico', label: 'Mexico' },
                { value: 'Canada', label: 'Canada' },
                { value: 'United Kingdom', label: 'United Kingdom' },
            ]
        },
    },
    {
        Header: 'Vehicle External ID',
        accessor: "Vehicle External ID",
        query: {
            field: "Vehicle External ID",
            matchingValue: 'like',
        },
        sortable: false,
        width: 280,
    },
    {
        Header: 'Vehicle VIN',
        accessor: "Vehicle VIN",
        query: {
            field: "Vehicle VIN",
            matchingValue: 'like',
        },
        sortable: false,
        width: 180,
    },
    {
        Header: 'Vehicle License Plate',
        accessor: "Vehicle License Plate",
        query: {
            field: "Vehicle License Plate",
            matchingValue: 'like',
        },
        sortable: false,
        width: 150,
    },

];
