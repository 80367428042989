import api from '../utils/api';

const proxy = ({ data }) => data;

const countries = () => api.get(`/location/countries`).then(proxy);

const history = () => api.get(`/campaigns`).then(proxy);

let cacheStates = {};
const states = (filters = []) => {
  const search = { filters };

  const query = `search=${JSON.stringify(search)}`;

  if (typeof cacheStates[query] === 'undefined') {
    cacheStates[query] = api.get(`/location/states?${query}`).then(proxy);
  }

  return cacheStates[query];
};

let cacheMembers = {};
const members = (filters = []) => {
  const search = { filters };

  const query = `search=${JSON.stringify(search)}`;

  if (typeof cacheMembers[query] === 'undefined') {
    cacheMembers[query] = api.get(`/campaigns/recipients/members?${query}`).then(proxy);
  }

  return cacheMembers[query];
};

let cacheMembersId = {};
const allMembersIds = (filters = []) => {
  const search = { filters };

  const query = `search=${JSON.stringify(search)}`;

  if (typeof cacheMembersId[query] === 'undefined') {
    cacheMembersId[query] = api.get(`/campaigns/recipients/members/id?${query}`).then(proxy);
  }

  return cacheMembersId[query];
};

let cache = {};
const search = (page, pageSize, filters = [], sorting = {}) => {
  const search = { filters };

  if (sorting && sorting.field) {
    search.direction = sorting.dir;
    search.sorting = sorting.sorting || 'name';
  }


  const query = `search=${JSON.stringify(search)}&offset=${page * pageSize}&size=${pageSize}`;

  if (typeof cache[query] === 'undefined') {
    cache[query] = api.get(`/campaigns/recipients/members?${query}`).then(proxy);
  }

  return cache[query];
};

const send = payload => api.post(`/campaigns`, payload).then(proxy);

export default {
  countries,
  states,
  members,
  allMembersIds,
  search,
  send,
  history
}
