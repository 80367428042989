import {createBrowserHistory} from 'history';
import {createStore, combineReducers, applyMiddleware} from 'redux';
import {routerReducer, routerMiddleware} from 'react-router-redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import notifier from './../middlewares/notifier';

import login from './login';
import members from './members';
import member from './member';
import vehicles from './vehicles';
import notification from './notification';
import reports from './reports';
import stats from './stats';
import payments from './payments';
import campaigns from './campaigns';
import support from './support';
import resetPasswordLogs from './resetPasswordLogs';
import loginLogs from './loginLogs';
import packages from './packages';
import consistencyChecks from './consistencyChecks';
import consistencyCheck from './consistencyCheck';

export const history = createBrowserHistory();
const middleware = routerMiddleware(history);
const store = createStore(
    combineReducers({
        login,
        members,
        member,
        vehicles,
        notification,
        reports,
        stats,
        payments,
        campaigns,
        support,
        loginLogs,
        resetPasswordLogs,
        packages,
        consistencyChecks,
        consistencyCheck,
        router: routerReducer,
    }),
    applyMiddleware(middleware, thunk, logger, notifier)
);

export default store;
