import moment from 'moment';
import { error } from './notification';
import stats from './../services/stats';

// Actions
const SET_SIGNUPS = 'admin/stats/SET_SIGNUPS';
const SET_DASHBOARD = 'admin/stats/SET_DASHBOARD';
const SET_EARNINGS = 'admin/stats/SET_EARNINGS';
const LOADING_LIST = 'admin/stats/LOADING_LIST';
const initialDashboard = {
  au:{
    name: 'Australia',
    earnings: {
      total:0,
      daily:[],
      unit:'$ (AUD)'
    },
    averageEarnings: {
      total:0,
      daily:[],
      unit:'$ (AUD)'
    },
    signups: {
      total:0,
      daily:[],
      unit:''
    },
    membersCount: {
      value: 0,
      unit: ''
    },
    membersWithUberCount: {
      value: 0,
      unit: ''
    }
  },
  ca:{
    name: 'Canada',
    earnings: {
      total:0,
      daily:[],
      unit:'$ (CAD)'
    },
    averageEarnings: {
      total:0,
      daily:[],
      unit:'$ (CAD)'
    },
    signups: {
      total:0,
      daily:[],
      unit:''
    },
    membersCount: {
      value: 0,
      unit: ''
    },
    membersWithUberCount: {
      value: 0,
      unit: ''
    }
  },
  mx:{
    name: 'Mexico',
    earnings: {
      total:0,
      daily:[],
      unit:'$ (MXN)'
    },
    averageEarnings: {
      total:0,
      daily:[],
      unit:'$ (MXN)'
    },
    signups: {
      total:0,
      daily:[],
      unit:''
    },
    membersCount: {
      value: 0,
      unit: ''
    },
    membersWithUberCount: {
      value: 0,
      unit: ''
    }
  },
  uk:{
    name: 'United Kingdom',
    earnings: {
      total:0,
      daily:[],
      unit:'£ (GBP)'
    },
    averageEarnings: {
      total:0,
      daily:[],
      unit:'£ (GBP)'
    },
    signups: {
      total:0,
      daily:[],
      unit:''
    },
    membersCount: {
      value: 0,
      unit: ''
    },
    membersWithUberCount: {
      value: 0,
      unit: ''
    }
  },
};

// initial state
const INITIAL_STATE = {
  signups: JSON.parse(localStorage.getItem('signups')) || null,
  earnings: JSON.parse(localStorage.getItem('earnings')) || null,
  dashboard: JSON.parse(localStorage.getItem('dashboard')) || initialDashboard,
  loading: false,
};

// Reducer
export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case LOADING_LIST:
      return {
        ...state,
        loading: action.payload,
      };

    case SET_SIGNUPS:
      const signupsList = {
        ...state,
        signups: action.payload
      };

      localStorage.setItem('signups', JSON.stringify(signupsList.signups));

      return signupsList;

    case SET_EARNINGS:
      const earningsList = {
        ...state,
        earnings: action.payload
      };

      localStorage.setItem('earnings', JSON.stringify(earningsList.earnings));

      return earningsList;

    case SET_DASHBOARD:
      const dashboardList = {
        ...state,
        dashboard: action.payload
      };

      localStorage.setItem('dashboard', JSON.stringify(dashboardList.dashboard));

      return dashboardList;

    default:
      return state;
  }
}

// Action Creators
// export function signups(dates) {
//   return function (dispatch) {
//     dispatch({ type: LOADING_LIST, payload: true });

//     const dateObj = dates ? dates : [
//       `startDate=${moment().subtract(7, 'days').format('YYYY-MM-DD')}`,
//       `endDate=${moment().format('YYYY-MM-DD')}`,
//     ]

//     stats.signups(dateObj.join('&'))
//       .then(({ data }) => {
//         dispatch({ type: SET_SIGNUPS, payload: data })
//         dispatch({ type: LOADING_LIST, payload: false });
//       })
//       .catch(error => dispatch(onFailure(error)));
//   }
// }



// export function earnings(dates) {
//   return function (dispatch) {
//     dispatch({ type: LOADING_LIST, payload: true });

//     const dateObj = dates ? dates : [
//       `startDate=${moment().subtract(7, 'days').format('YYYY-MM-DD')}`,
//       `endDate=${moment().format('YYYY-MM-DD')}`,
//     ]

//     stats.earnings(dateObj.join('&'))
//       .then(({ data }) => {
//         dispatch({ type: SET_EARNINGS, payload: data })
//         dispatch({ type: LOADING_LIST, payload: false });
//       })
//       .catch(error => dispatch(onFailure(error)));
//   }
// }

export function getDashboard(dates) {
  return function (dispatch) {
    dispatch({ type: LOADING_LIST, payload: true });

    const dateObj = dates ? dates : [
      `startDate=${moment().subtract(30, 'days').format('YYYY-MM-DD')}`,
      `endDate=${moment().format('YYYY-MM-DD')}`,
    ]

    stats.dashboard(dateObj.join('&'))
      .then(data => {
        dispatch({ type: SET_DASHBOARD, payload: data })
        dispatch({ type: LOADING_LIST, payload: false });
      })
      .catch(({ response }) => {
        dispatch(error(response));

        dispatch({ type: LOADING_LIST, payload: false });
      });
  }
}
