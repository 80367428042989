import moment from 'moment';
import { error } from './notification';
import packages from './../services/packages';

// Actions
const SET_LIST = 'admin/packages/SET_LIST';
const SET_PAGE = 'admin/packages/SET_PAGE';
const LOADING_LIST = 'admin/packages/LOADING_LIST';

const INITIAL_STATE = {
  list: [],
  sync_time: null,
  loading: false,
  page: {
    total: 0,
    currentPage: 0,
    pageSize: 100,
  },
};

// Reducer
export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case LOADING_LIST:
      return { ...state, loading: action.payload };

    case SET_LIST:
      return { ...state, list: action.payload, sync_time: moment().format('x') };

    case SET_PAGE:
      return {
        ...state,
        page: {
          total: action.payload.total,
          currentPage: action.payload.offset / action.payload.size,
          pageSize: action.payload.size,
        },
      };

    default:
      return state;
  }
}

export function search(page = 0, pageSize = 100, filters = [], sorting = []) {
  return function(dispatch) {
    dispatch({ type: LOADING_LIST, payload: true });

    packages.search(page, pageSize, filters, sorting)
      .then(({ data, meta }) => {
        dispatch({ type: SET_LIST, payload: data });
        dispatch({ type: SET_PAGE, payload: meta });
        dispatch({ type: LOADING_LIST, payload: false });
      })
      .catch(({ response }) => {
        dispatch(error(response));

        dispatch({ type: LOADING_LIST, payload: false });
      });
  };
}
