import moment from 'moment';
import { error } from './notification';
import { push } from 'react-router-redux';
import vehicles from '../services/vehicles';

// Actions
const SET_LIST = 'admin/vehicles/SET_LIST';
const SET_PAGE = 'admin/vehicles/SET_PAGE';
const SET_VEHICLE = 'admin/vehicles/SET_VEHICLE';
const LOADING_LIST = 'admin/vehicles/LOADING_LIST';
const LOADING_VEHICLE = 'admin/vehicles/LOADING_VEHICLE';

const INITIAL_STATE = {
  list: [],
  vehicle: {},
  sync_time: null,
  loading: false,
  loadingVehicle: false,
  page: {
    total: 0,
    currentPage: 0,
    pageSize: 100
  }
};

// Reducer
export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case LOADING_LIST:
      return {
        ...state,
        loading: action.payload
      };

    case SET_LIST:
      const vehicles = {
        ...state,
        list: action.payload,
        sync_time: moment().format('x'),
      };

      return vehicles;


    case SET_PAGE:
      const page = {
        ...state,
        page: {
          total: action.payload.total,
          currentPage: action.payload.offset /  action.payload.size,
          pageSize: action.payload.size
        },
      };

      return page;

    case LOADING_VEHICLE:
      return {
        ...state,
        loadingVehicle: action.payload,
      };

    case SET_VEHICLE:
      const newState = {
        ...state,
        vehicle: action.payload,
      };

      return newState;

    default:
      return state;
  }
}

export function find(id) {
  return function(dispatch) {
    dispatch({ type: LOADING_VEHICLE, payload: true });

    vehicles.find(id)
      .then(({ vehicle, devices, assignments }) => {
        const dashboardExtra = { devices, assignments };
        dispatch({ type: SET_VEHICLE, payload: { ...vehicle, dashboardExtra}});
        dispatch({ type: LOADING_VEHICLE, payload: false });
      })
      .catch(({ response }) => {
        dispatch(error(response));

        dispatch({ type: LOADING_VEHICLE, payload: false });

        dispatch(push('/vehicles'));
      });
  };
}

// Action Creators
export function search(page = 0, pageSize = 100, filters = [], sorting = []) {

  return function(dispatch) {
    dispatch({ type: LOADING_LIST, payload: true });

    vehicles.search(page, pageSize, filters, sorting)
      .then(({ data, meta}) => {
        dispatch({ type: SET_LIST, payload: data });
        dispatch({ type: SET_PAGE, payload: meta });
        dispatch({ type: LOADING_LIST, payload: false });
      })
      .catch(({ response }) => {
        dispatch(error(response));

        dispatch({ type: LOADING_LIST, payload: false });
      });
  };
}
