import moment from 'moment';
import { error } from './notification';
import members from './../services/members';

// Actions
export const SET_LIST = 'admin/members/SET_LIST';
export const SET_PAGE = 'admin/members/SET_PAGE';
export const LOADING_LIST = 'admin/members/LOADING_LIST';
export const REMOVE_MEMBER = 'admin/members/REMOVE_MEMBER';
export const SET_EXPORT_FILTERS = 'admin/members/SET_EXPORT_FILTERS';

export const INITIAL_STATE = {
  list: [],
  exportMembersFilters: {},
  sync_time: JSON.parse(localStorage.getItem('sync_time')) || null,
  loading: false,
  page: {
    total: 0,
    currentPage: 0,
    pageSize: 100
  }
};

// Reducer
export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case LOADING_LIST:
      return {
        ...state,
        loading: action.payload
      };

    case SET_LIST:
      const members = {
        ...state,
        list: action.payload,
        sync_time: moment().format('x'),
      };

      return members;

    case SET_PAGE:
      const page = {
        ...state,
        page: {
          total: action.payload.total,
          currentPage: action.payload.offset /  action.payload.size,
          pageSize: action.payload.size
        },
      };

      return page;

    case REMOVE_MEMBER:
      const withoutRemovedMember = {
        ...state,
        list: state.list.filter(member => member.id !== action.payload),
      };

      return withoutRemovedMember;

    case SET_EXPORT_FILTERS:
      const exportMembersFilters = {
        ...state,
        exportMembersFilters: action.payload,
      };

      return exportMembersFilters;

    default:
      return state;
  }
}

// Action Creators
export function remove(id) {
  return function (dispatch) {
    members.remove(id)
      .then(() => {
        dispatch({ type: REMOVE_MEMBER, payload: id });
      })
      .catch(({ response }) => {
        dispatch(error(response));

        console.log('Remove Member Error', error);
      });
  };
}

export function setExportMembersFilters(payload) {
  return function (dispatch) {
    dispatch({ type: SET_EXPORT_FILTERS, payload });
  };
}

export function search(page = 0, pageSize = 100, filters = [], sorting = []) {
  return function(dispatch) {
    dispatch({ type: LOADING_LIST, payload: true });

    members.search(page, pageSize, filters, sorting)
      .then(({ data, meta}) => {
        dispatch(setExportMembersFilters({
          page, pageSize, filters, sorting
        }));
        dispatch({ type: SET_LIST, payload: data });
        dispatch({ type: SET_PAGE, payload: meta });
        dispatch({ type: LOADING_LIST, payload: false });
      })
      .catch(({ response }) => {
        dispatch(error(response));

        dispatch({ type: LOADING_LIST, payload: false });
      });
  };
}
