import axios from 'axios';
import get from 'lodash/get';
import config from '../config';

const api = axios.create({
  baseURL: config.api_url,
  timeout: 60000,
  // withCredentials: true,
  // mode: 'no-cors',
  // credentials: 'same-origin',
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
  }
});

// interceptor for Authorization
if (api) {
  api.interceptors.request.use(response => {
      const { headers } = response;
      const tokens = JSON.parse(localStorage.getItem('tokens'));
      const sessionToken = get(tokens, 'session.access_token');

      if (sessionToken) {
        headers['Authorization'] = `Bearer ${sessionToken}`;
      }

      return { ...response, headers };
    }, function (error) {
      return Promise.reject(error);
    }
  );
}

export default api;
