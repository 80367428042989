import api from './../utils/api';

// for average Daily, payload format is: startDate=2018-01-01&endDate=2018-02-04

const proxy = ({ data }) => data;

const avgDailySignups = payload => api.get(`/reports/average-daily-signups?${payload}`).then(proxy);
const avgDailyEarnings = payload => api.get(`/reports/average-daily-earnings?${payload}`).then(proxy);
const dashboard = payload => api.get(`/dashboard?${payload}`).then(proxy);

export default {
  signups: avgDailySignups,
  earnings: avgDailyEarnings,
  dashboard,
}
